import { ActionItem } from 'components/ManageDropdownButton/ActionItem';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

export const ShowShareLinksActionItem = (props: Props): ReactElement => {
  const { onClick } = props;
  const { t: translate } = useTranslation();

  return <ActionItem iconName="list" text={translate('surveyManage.share')} onClick={onClick} />;
};
