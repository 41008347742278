/* eslint-disable id-denylist */
import { ILandscapeHeaderElement } from '@zencity/reporting-types';
import { SurveysReportGeneratorDataItemKey } from '../../constants';
import { DocumentFragment } from '../documentFragment';
import { sectionCoverPage } from '../utils/sectionCoverPage';
import { TEMPLATE_TYPOGRAPHY } from '../templateTypography';
import { TABLE_DEFAULT_BORDER_COLOR, reportTemplateTable, reportTemplateTableCell } from '../utils/tableTemplate';
import { lineBreakElement } from '../utils/lineBreakElement';

const geographicsBreakdownCoverPage: DocumentFragment = sectionCoverPage({
  titleChunks: ['Across the', 'Community'],
});

const leftColumnGeographicsBreakdownTable = reportTemplateTableCell({
  data: [
    {
      name: 'heading2',
      children: [
        {
          data: `[Map]`,
          attributes: {
            fontSize: TEMPLATE_TYPOGRAPHY.fontSizes.heading4,
            fontColor: TEMPLATE_TYPOGRAPHY.colors.red90,
          },
        },
      ],
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '56%',
});

const middleColumnGeographicsBreakdownTable = reportTemplateTableCell({
  data: [lineBreakElement],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '3%',
});
const rightColumnGeographicsBreakdownTable = reportTemplateTableCell({
  data: [
    {
      name: 'reactItem',
      attributes: {
        definitionKey: SurveysReportGeneratorDataItemKey.AREAS_SENTIMENT_TABLE,
      },
    },
  ],
  tableCellBorderColor: TABLE_DEFAULT_BORDER_COLOR,
  tableCellWidth: '40%',
});

const geographicsBreakdownTable = reportTemplateTable({
  tableColumns: [
    leftColumnGeographicsBreakdownTable,
    middleColumnGeographicsBreakdownTable,
    rightColumnGeographicsBreakdownTable,
  ],
  tableBorderColor: TABLE_DEFAULT_BORDER_COLOR,
});

interface GeographicsBreakdownParams {
  headerElement: ILandscapeHeaderElement;
}

export const geographicsBreakdown = (props: GeographicsBreakdownParams): DocumentFragment => {
  const { headerElement } = props;

  return [
    headerElement,
    ...geographicsBreakdownCoverPage,
    headerElement,
    geographicsBreakdownTable,
    {
      name: 'pageBreak',
    },
  ];
};
