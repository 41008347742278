/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSurveys } from 'services/survey';
import { updateSurveyGroupCadence, updateSurveyGroupTitleForDisplay } from 'services/surveyGroup';
import { PaginatedResults, RequestStatus } from 'types/misc';
import { Survey } from 'types/survey';

export interface SurveysState {
  surveys: { [surveyId: number]: Survey };
  fetchStatus: RequestStatus;
  updateStatus: RequestStatus;
  errorMessage?: string;
  nextPage?: number;
}

const initialState: SurveysState = {
  surveys: {},
  fetchStatus: RequestStatus.IDLE,
  updateStatus: RequestStatus.IDLE,
  nextPage: 1,
};

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    addOrUpdateSurvey: (state: SurveysState, action: PayloadAction<Survey>) => {
      const survey = action.payload;
      state.surveys[survey.id] = survey;
    },
    setStatusToIdle: (state) => {
      state.fetchStatus = RequestStatus.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSurveys.pending, (state: SurveysState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(fetchSurveys.fulfilled, (state: SurveysState, action: PayloadAction<PaginatedResults<Survey>>) => {
        state.fetchStatus = RequestStatus.SUCCESS;
        const { results, next } = action.payload;
        results.forEach((survey) => {
          state.surveys[survey.id] = survey;
        });
        state.nextPage = next && state.nextPage ? state.nextPage + 1 : undefined;
      })
      .addCase(fetchSurveys.rejected, (state: SurveysState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(updateSurveyGroupTitleForDisplay.rejected, (state: SurveysState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateSurveyGroupTitleForDisplay.fulfilled,
        (
          state: SurveysState,
          action: PayloadAction<{ language: string; surveyId: number; translatedText: string }>,
        ) => {
          const { language, surveyId, translatedText } = action.payload;
          const survey = state.surveys[surveyId];
          const surveyTitleForDisplay = survey.survey_group.translations?.title_for_display ?? {};
          surveyTitleForDisplay[language] = translatedText;
          state.surveys[surveyId] = survey;
          state.updateStatus = RequestStatus.SUCCESS;
          state.errorMessage = undefined;
        },
      )
      .addCase(updateSurveyGroupCadence.rejected, (state: SurveysState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateSurveyGroupCadence.fulfilled,
        (state: SurveysState, action: PayloadAction<{ surveyId: number }>) => {
          const { surveyId } = action.payload;
          const survey = state.surveys[surveyId];
          state.surveys[surveyId] = survey;
          state.updateStatus = RequestStatus.SUCCESS;
          state.errorMessage = undefined;
        },
      );
  },
});

export const { addOrUpdateSurvey, setStatusToIdle } = surveysSlice.actions;
export default surveysSlice;
