/* eslint-disable max-lines-per-function,max-statements */
import { Header } from 'components/Header/Header';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { ManageDropdownButton } from 'components/ManageDropdownButton/ManageDropdownButton';
import { SurveyManageList } from 'components/SurveyManageList/SurveyManageList';
import { SurveyPreview } from 'components/SurveyPreview/SurveyPreview';
import { SurveyTranslationFormLayout } from 'components/SurveyTranslationFormLayout/SurveyTranslationFormLayout';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentGenericQuestions,
  useCurrentSurvey,
  useCurrentSurveyQuestions,
} from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouterPath } from 'routerPaths';
import { setCurrentDisplay, setCurrentLanguage, setCurrentSurvey, SurveyManageDisplay } from 'slices/surveyManage';
import { RootState } from 'store';
import { logger } from 'utils/logger';
import { isNumeric, statusIsIdle, statusIsLoading } from 'utils/misc';
import { fetchSurveyLanguagesBySurveyId } from 'services/surveyLanguage';
import { ZCDNotificationContainer, ZCDSpinner, zcdNotify } from '@zencity/common-ui';
import styles from './SurveyManageScreen.module.scss';

/**
 * This page will allow a user to publish and view a preview of a survey.
 */
export const SurveyManageScreen = (): ReactElement => {
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { questions, fetchStatus: questionsFetchStatus } = useAppSelector((state: RootState) => state.questions);
  const { defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const { surveys, fetchStatus: surveysFetchStatus } = useAppSelector((state: RootState) => state.surveys);
  const {
    currentSurvey,
    fetchStatus: surveyLanguagesFetchStatus,
    errorMessage: surveyLanguagesErrorMessage,
    currentDisplay,
  } = useAppSelector((state: RootState) => state.surveyManage);

  const { t: translate } = useTranslation();

  const { surveyId = '' } = useParams();
  if (!isNumeric(surveyId)) {
    throw new Error(`Survey ID is not a number: ${surveyId}`);
  }
  const surveyIdAsNumber = parseInt(surveyId);

  const surveysError = useCurrentSurvey(surveyIdAsNumber);
  const questionsError = useCurrentSurveyQuestions(surveyIdAsNumber);
  const [loadingGenericQuestions, genericQuestionsError] = useCurrentGenericQuestions(questions);

  useEffect(() => {
    if (surveysError) {
      toastError();
      logger.error(surveysError.message);
    } else if (questionsError) {
      toastError();
      logger.error(questionsError);
    } else if (genericQuestionsError) {
      toastError();
      logger.error(genericQuestionsError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsError, surveysError, genericQuestionsError]);

  /**
   * Fetch the survey's Survey Languages if the fetched Survey Languages do not
   * align with the survey ID, or no Survey Languages have been fetched yet.
   */
  useEffect(() => {
    const currentSurveyLanguagesAreNotFetched =
      statusIsIdle(surveyLanguagesFetchStatus) || currentSurvey?.id !== surveyIdAsNumber;

    if (currentSurveyLanguagesAreNotFetched) {
      dispatch(fetchSurveyLanguagesBySurveyId(surveyIdAsNumber));
      dispatch(setCurrentSurvey(surveys[surveyIdAsNumber]));
    }

    if (surveyLanguagesErrorMessage) {
      zcdNotify.error(translate('toastMessages.serverRequestError'));
      logger.error(surveyLanguagesErrorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyIdAsNumber, surveyLanguagesErrorMessage, surveyLanguagesFetchStatus, currentSurvey]);

  /**
   * Cleanup for when a user leaves the "Manage" screen.
   */
  useEffect(
    () => () => {
      dispatch(setCurrentLanguage(defaultLanguage));
      dispatch(setCurrentDisplay(SurveyManageDisplay.PREVIEW));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!currentSurvey) {
    return <ZCDSpinner />;
  }

  return (
    <>
      <LoaderMask
        isLoading={
          statusIsLoading(questionsFetchStatus) ||
          statusIsLoading(surveyLanguagesFetchStatus) ||
          statusIsLoading(surveysFetchStatus) ||
          loadingGenericQuestions
        }
      >
        <Header
          titleText={`${currentSurvey.survey_group.title_for_display}`}
          backLink={{
            text: `<- ${translate('surveyManage.backLink')}`,
            path: RouterPath.MAIN,
          }}
          actions={[<ManageDropdownButton key={currentSurvey.id} survey={currentSurvey} />]}
          description={`${translate('common.status')}: "${currentSurvey.status}"`}
        />
        <div className={styles.surveyManageScreen}>
          <SurveyManageList surveyId={surveyIdAsNumber} />
          {currentDisplay === SurveyManageDisplay.PREVIEW && <SurveyPreview />}
          {currentDisplay === SurveyManageDisplay.TRANSLATION && <SurveyTranslationFormLayout />}
        </div>
      </LoaderMask>
      <ZCDNotificationContainer />
    </>
  );
};
