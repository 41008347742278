/* eslint-disable max-lines-per-function */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addNewEndScreen,
  deleteEndScreen,
  fetchEndScreensBySurveyId,
  updateEndScreen,
  updateEndScreenDescriptionTranslation,
  updateEndScreenTitleTranslation,
} from 'services/endScreen';
import { EndScreen } from 'types/endScreen';
import { PaginatedResults, RequestStatus } from 'types/misc';

interface EndScreensState {
  endScreens: EndScreen[];
  fetchStatus: RequestStatus;
  deleteRequestStatus: RequestStatus;
  updateStatus: RequestStatus;
  addRequestStatus: RequestStatus;
  errorMessage?: string;
}

const initialState: EndScreensState = {
  endScreens: [],
  fetchStatus: RequestStatus.IDLE,
  addRequestStatus: RequestStatus.IDLE,
  deleteRequestStatus: RequestStatus.IDLE,
  updateStatus: RequestStatus.IDLE,
};

const slice = createSlice({
  name: 'endScreens',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEndScreensBySurveyId.pending, (state: EndScreensState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(
        fetchEndScreensBySurveyId.fulfilled,
        (state: EndScreensState, action: PayloadAction<PaginatedResults<EndScreen>>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          state.endScreens = action.payload.results;
        },
      )
      .addCase(fetchEndScreensBySurveyId.rejected, (state: EndScreensState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEndScreen.pending, (state: EndScreensState) => {
        state.deleteRequestStatus = RequestStatus.LOADING;
      })
      .addCase(
        deleteEndScreen.fulfilled,
        (state: EndScreensState, action: PayloadAction<{ deletedEndScreenId: number }>) => {
          state.deleteRequestStatus = RequestStatus.SUCCESS;
          const { deletedEndScreenId } = action.payload;
          const screensCopy = [...state.endScreens];
          const index = screensCopy.findIndex((screen) => screen.id === deletedEndScreenId);
          if (index > -1) {
            screensCopy.splice(index, 1);
          }
          state.endScreens = screensCopy;
        },
      )
      .addCase(deleteEndScreen.rejected, (state: EndScreensState, action) => {
        state.deleteRequestStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEndScreen.pending, (state: EndScreensState) => {
        state.updateStatus = RequestStatus.LOADING;
      })
      .addCase(updateEndScreen.fulfilled, (state: EndScreensState, action: PayloadAction<EndScreen>) => {
        state.updateStatus = RequestStatus.SUCCESS;
        const updatedQuestion = action.payload;
        state.endScreens = state.endScreens.map((instance) =>
          instance.id === updatedQuestion.id ? updatedQuestion : instance,
        );
      })
      .addCase(updateEndScreen.rejected, (state: EndScreensState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(addNewEndScreen.pending, (state: EndScreensState) => {
        state.addRequestStatus = RequestStatus.LOADING;
      })
      .addCase(
        addNewEndScreen.fulfilled,
        (state: EndScreensState, action: PayloadAction<{ newEndScreen: EndScreen }>) => {
          state.addRequestStatus = RequestStatus.SUCCESS;
          const { newEndScreen } = action.payload;
          state.endScreens.push(newEndScreen);
          state.errorMessage = undefined;
        },
      )
      .addCase(addNewEndScreen.rejected, (state: EndScreensState, action) => {
        state.errorMessage = action.error.message;
        state.addRequestStatus = RequestStatus.ERROR;
      })
      .addCase(updateEndScreenTitleTranslation.rejected, (state: EndScreensState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateEndScreenTitleTranslation.fulfilled,
        (state: EndScreensState, action: PayloadAction<EndScreen>) => {
          const updatedScreen = action.payload;
          state.endScreens = state.endScreens.map((instance) => {
            if (instance.id === updatedScreen.id) {
              return updatedScreen;
            }
            return instance;
          });
          state.updateStatus = RequestStatus.SUCCESS;
        },
      )
      .addCase(updateEndScreenDescriptionTranslation.rejected, (state: EndScreensState, action) => {
        state.updateStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateEndScreenDescriptionTranslation.fulfilled,
        (state: EndScreensState, action: PayloadAction<EndScreen>) => {
          const updatedScreen = action.payload;
          state.endScreens = state.endScreens.map((instance) => {
            if (instance.id === updatedScreen.id) {
              return updatedScreen;
            }
            return instance;
          });
          state.updateStatus = RequestStatus.SUCCESS;
        },
      );
  },
});

export default slice;
