import { ZCDCard, HorizontalSeparator } from '@zencity/common-ui';
import { Nullable } from '@zencity/survey-types';
import { FallbackRule } from 'components/LogicJumpDialog/components/FallbackRule/FallbackRule';
import { LogicJumpForm } from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm';
import { Spacer } from 'components/Spacer/Spacer';
import { useLogicJumpFormItems } from 'customHooks/logicJumps';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionItem } from 'types/questions';

interface Props {
  questionItem: QuestionItem;
}

export const LogicJumpFormItemsList = ({ questionItem }: Props): Nullable<ReactElement> => {
  const { t: translate } = useTranslation();
  const { logicJumpFormItems } = useLogicJumpFormItems(questionItem);
  const fallbackRuleFormItem = logicJumpFormItems.find((logicJumpFormItem) => logicJumpFormItem.always);
  const logicJumpFormItemsWithoutFallbackRule = logicJumpFormItems.filter(
    (logicJumpFormItem) => !logicJumpFormItem.always,
  );

  if (!logicJumpFormItemsWithoutFallbackRule.length && !fallbackRuleFormItem) {
    return null;
  }
  return (
    <>
      <h3>{translate('questionInformationDialog.logicJumpFormItemsList.title')}</h3>
      <ZCDCard>
        {logicJumpFormItemsWithoutFallbackRule.map((logicJumpFormItem, index) => (
          <div key={logicJumpFormItem.id}>
            <LogicJumpForm
              logicJumpFormItem={logicJumpFormItem}
              handleOnSetLogicJumpFormItem={() => {}}
              disableEditing
            />
            {index !== logicJumpFormItemsWithoutFallbackRule.length - 1 && <HorizontalSeparator />}
          </div>
        ))}
        {fallbackRuleFormItem && (
          <>
            <HorizontalSeparator />
            <Spacer size="medium" orientation="vertical" />
            <FallbackRule
              crossroadQuestionItem={questionItem}
              logicJumpFormItemValues={{
                selectedDestinationEndScreenId: fallbackRuleFormItem.selectedDestinationEndScreenId,
                selectedDestinationQuestionId: fallbackRuleFormItem.selectedDestinationQuestionId,
                id: fallbackRuleFormItem.id,
              }}
              handleOnSetFallbackRule={() => {}}
              isDisabled
            />
          </>
        )}
      </ZCDCard>
    </>
  );
};
