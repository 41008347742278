import React from 'react';
import { BubbleChartDataType } from 'screens/WidgetGenerator/reportGeneratorComponents/components/TopicsBubbleChartHOC/SentimentFilter';
import { capitalizeString } from 'utils/misc';
import { v4 } from 'uuid';
import { ClassificationSubTopicResponses } from '../../utils/misc';
import './Bubble.scss';

interface BubbleProps {
  widgetData: ClassificationSubTopicResponses[];
  scale: number;
  sentiment?: BubbleChartDataType;
}

enum CircleSize {
  SMALL = 'smallCircle',
  MEDIUM = 'mediumCircle',
}

export function Bubble(props: BubbleProps): React.ReactElement {
  const { widgetData, scale, sentiment } = props;

  const resolveType = (index: number) => {
    if (sentiment === BubbleChartDataType.POSITIVE) return BubbleChartDataType.POSITIVE;

    if (sentiment === BubbleChartDataType.NEGATIVE) return BubbleChartDataType.NEGATIVE;

    if (!sentiment || sentiment === BubbleChartDataType.ALL)
      if (index <= 2) {
        return BubbleChartDataType.POSITIVE;
      }
    return BubbleChartDataType.NEGATIVE;
  };

  const filterDataBySentiment = () => {
    if (sentiment === BubbleChartDataType.POSITIVE) {
      return widgetData.slice(0, 3);
    }
    if (sentiment === BubbleChartDataType.NEGATIVE) {
      return widgetData.slice(-3);
    }
    return widgetData;
  };

  const dataToDisplay = filterDataBySentiment();

  const resolveCircleSize = (width: number) => {
    if (width <= 160) {
      return CircleSize.SMALL;
    }
    if (width <= 300) {
      return CircleSize.MEDIUM;
    }
    return '';
  };
  let maxResponses = 0;
  const exclWords = ['for', 'to', 'and', 'of'];

  return (
    <div className="bubbleSurveysWidgetGenerator">
      {dataToDisplay.map((element, index) => {
        const { responses = 0, classificationSubTopicValue = '' } = element;
        const width = responses * scale;
        let bubbleText = classificationSubTopicValue.replaceAll('-', ' ');
        bubbleText = capitalizeString({ string: bubbleText, wordsToExclude: exclWords });
        maxResponses = maxResponses < responses ? responses : maxResponses;
        return (
          <div className="circleCont" key={v4()}>
            <div
              className={`circle ${resolveCircleSize(width)} ${resolveType(index)}`}
              style={{ width, height: width }}
            >
              <div className="innerCircle">
                <p>{bubbleText}</p>
                <p>{responses} responses</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
