/* eslint-disable react/no-multi-comp */
import { MissingTranslation } from 'components/MissingTranslation/MissingTranslation';
import { QuestionFlags } from 'components/QuestionFlags/QuestionFlags';
import { QuestionIndex } from 'components/QuestionIndex/QuestionIndex';
import { useAppSelector, useSelectedLanguage } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';
import { findQuestionConfig, resolveRequiredConfig } from 'utils/genericAndQuestionConfigs';
import { MarkdownHOC } from 'components/MarkdownHOC/MarkdownHOC';
import { ZCDIconButton } from '@zencity/common-ui';
import { QuestionInformationDialog } from 'components/QuestionInformationDialog/QuestionInformationDialog';
import { useClientNameTokenReplacement } from '../../customHooks/useClientNameTokenReplacement/useClientNameTokenReplacement';
import styles from './ViewQuestionHeader.module.scss';

interface Props {
  questionItem: QuestionItem;
  withLargeText: boolean;
  resolvedIndex: string;
  displayInformation?: boolean;
}

/**
 * Displays the Question's index, text and description.
 */
// eslint-disable-next-line complexity,max-statements,max-lines-per-function
export const ViewQuestionHeader = (props: Props): ReactElement => {
  const { questionItem, withLargeText, resolvedIndex, displayInformation = false } = props;
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const { logicJumpsPerCrossroadQuestion } = useAppSelector((state: RootState) => state.logicJumps);

  const [containsEditedFields, setContainsEditedFields] = useState(false);
  const [showQuestionFlags, setShowQuestionFlags] = useState(false);
  const [isInformationDialogOpen, setIsInformationDialogOpen] = useState(false);
  const { clientNameTokenReplacement } = useClientNameTokenReplacement();

  const containsLogicJumps = !!logicJumpsPerCrossroadQuestion[questionItem.item.id];
  const toggleInformationDialog = () => {
    setIsInformationDialogOpen(!isInformationDialogOpen);
  };
  const closeInformationDialog = () => {
    setIsInformationDialogOpen(false);
  };

  useEffect(() => {
    // TODO: Add the truthy values for if there are specific countries.
    const displayFlagInformation = containsEditedFields || containsLogicJumps;
    setShowQuestionFlags(displayFlagInformation);
  }, [containsEditedFields, questionItem, containsLogicJumps]);

  const selectedLanguage = useSelectedLanguage();
  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const questionTextTranslated = useMemo(() => {
    if (!selectedLanguage) {
      // Use the default language text.
      const overriddenText = questionItem.item.overridden_text;
      if (overriddenText) {
        setContainsEditedFields(true);

        return overriddenText;
      }

      return genericQuestion?.text;
    }

    // Use the translated text.
    const translatedOverriddenText = questionItem.item.translations?.overridden_text[selectedLanguage];
    if (translatedOverriddenText) {
      setContainsEditedFields(true);

      return translatedOverriddenText;
    }

    return genericQuestion?.translations?.text[selectedLanguage] || '';
  }, [questionItem, genericQuestion, selectedLanguage]);

  const questionDescriptionTranslated = useMemo(() => {
    const translationDescriptionConfig = findQuestionConfig(
      questionItem.item,
      genericQuestion,
      QuestionConfigType.DESCRIPTION,
      selectedLanguage,
    );

    if (translationDescriptionConfig?.isOverridden) {
      setContainsEditedFields(true);
    }

    return translationDescriptionConfig?.configValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionItem, genericQuestion, selectedLanguage]);

  const questionDescription = useMemo(() => {
    const descriptionConfig = findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.DESCRIPTION);

    if (descriptionConfig?.isOverridden) {
      setContainsEditedFields(true);
    }

    return descriptionConfig?.configValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionItem, genericQuestion]);

  const questionIsRequired = useMemo(() => {
    const requiredConfig = findQuestionConfig(
      questionItem.item,
      genericQuestion,
      QuestionConfigType.REQUIRED,
      selectedLanguage,
    );

    if (requiredConfig?.isOverridden) {
      setContainsEditedFields(true);
    }

    return resolveRequiredConfig(requiredConfig?.configValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericQuestion, questionItem, selectedLanguage]);

  useEffect(() => {
    const containsEditedConfigs = questionItem.item.configs.some((questionConfig) => {
      const questionConfigItem = findQuestionConfig(
        questionItem.item,
        genericQuestion,
        questionConfig.config_name,
        selectedLanguage,
      );
      return questionConfigItem?.isOverridden;
    });
    if (containsEditedConfigs) {
      setContainsEditedFields(true);
    }
  }, [questionItem.item, genericQuestion, selectedLanguage]);

  const requiredIndication = questionIsRequired ? <span className={styles.requiredIndication}>*</span> : <></>;

  const ConditionalHeading: React.FC<{ children: React.ReactNode }> = ({ children }): ReactElement =>
    withLargeText ? (
      <h2 className={styles.conditionalHeading}>{children}</h2>
    ) : (
      <h3 className={styles.conditionalHeading}>{children}</h3>
    );

  return (
    <div className={styles.questionViewEditGrid}>
      <QuestionIndex resolvedIndex={resolvedIndex} />
      <div>
        <div className={styles.questionHeader}>
          <ConditionalHeading>
            <MarkdownHOC text={clientNameTokenReplacement(questionTextTranslated)} />
            {questionTextTranslated && requiredIndication}
            {selectedLanguage && !questionTextTranslated && (
              <MissingTranslation text={genericQuestion.text} requiredIndication={requiredIndication} />
            )}
          </ConditionalHeading>
          {displayInformation && <ZCDIconButton icon="info" variant="menu" onClick={toggleInformationDialog} />}
          {showQuestionFlags && <QuestionFlags editedFields={containsEditedFields} logicJumps={containsLogicJumps} />}
        </div>
        {questionDescriptionTranslated && (
          <div className={styles.description}>
            <MarkdownHOC text={questionDescriptionTranslated} />
          </div>
        )}
        {selectedLanguage && questionDescription && !questionDescriptionTranslated && (
          <div className={styles.description}>
            <MissingTranslation text={questionDescription} />
          </div>
        )}
      </div>
      {displayInformation && (
        <QuestionInformationDialog
          questionItem={questionItem}
          resolvedIndex={resolvedIndex}
          isOpen={isInformationDialogOpen}
          onClose={closeInformationDialog}
        />
      )}
    </div>
  );
};
