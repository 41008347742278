import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { DestinationType } from '@zencity/survey-types';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { useAppSelector } from 'customHooks/hooks';
import i18next from 'i18next';
import React, { ReactElement, useState } from 'react';
import { RootState } from 'store';
import { EndScreen } from 'types/endScreen';
import { Question, ResolvedIndexes } from 'types/questions';
import styles from './DestinationQuestionEndScreenSelect.module.scss';

const PLACEHOLDER_OPTION: SelectOption = {
  value: 'placeholder',
  label: i18next.t('logicJumpDialog.selectDestinationQuestionEndScreen.placeholderOptionLabel'),
};

interface Props {
  onChange: (updatedFormValues: LogicJumpFormItemChangeType) => void;
  logicJumpFormItemValues: Pick<
    LogicJumpFormItem,
    'selectedDestinationEndScreenId' | 'selectedDestinationQuestionId' | 'crossroadQuestionItem' | 'inEditMode' | 'id'
  >;
  includePlaceholderOption?: boolean;
  isDisabled?: boolean;
}

const getDestinationIdFromSelectOptionValue = (value: string): number => parseInt(value.split('-')[1]);

interface GenerateSelectOptionsParams {
  questions: Question[];
  endScreens: EndScreen[];
  questionsStartIndex: number;
  resolvedIndexes: ResolvedIndexes;
  includePlaceholderOption?: boolean;
}
const generateSelectOptions = ({
  questions,
  endScreens,
  questionsStartIndex,
  resolvedIndexes,
  includePlaceholderOption,
}: GenerateSelectOptionsParams): SelectOption[] => {
  const questionOptions = questions
    .map<SelectOption>((question) => ({
      value: `${DestinationType.QUESTION}-${question.id}`,
      label: `Question #${resolvedIndexes[question.id]}: ${question.text}`,
    }))
    .slice(questionsStartIndex);
  const endScreenOptions = endScreens.map<SelectOption>((endScreen) => ({
    value: `${DestinationType.END_SCREEN}-${endScreen.id}`,
    label: endScreen.title,
  }));
  if (includePlaceholderOption) {
    return [PLACEHOLDER_OPTION, ...questionOptions, ...endScreenOptions];
  }
  return [...questionOptions, ...endScreenOptions];
};

const initializeSelectedOption = (
  selectOptions: SelectOption[],
  selectedDestinationEndScreenId?: number,
  selectedDestinationQuestionId?: number,
): SelectOption | undefined => {
  if (!selectOptions.length) {
    return undefined;
  }
  if (selectedDestinationQuestionId) {
    return selectOptions.find(
      (option) => option.value === `${DestinationType.QUESTION}-${selectedDestinationQuestionId}`,
    );
  }
  if (selectedDestinationEndScreenId) {
    return selectOptions.find(
      (option) => option.value === `${DestinationType.END_SCREEN}-${selectedDestinationEndScreenId}`,
    );
  }
  const placeholderOption = selectOptions.find((option) => option.value === PLACEHOLDER_OPTION.value);
  if (placeholderOption) {
    return placeholderOption;
  }
  return undefined;
};

export const DestinationQuestionEndScreenSelect = (props: Props): ReactElement => {
  const { onChange, logicJumpFormItemValues, includePlaceholderOption, isDisabled = false } = props;
  const { questions } = useAppSelector((state: RootState) => state.questions);
  const { endScreens } = useAppSelector((state: RootState) => state.endScreens);
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);

  const { selectedDestinationQuestionId, selectedDestinationEndScreenId, crossroadQuestionItem, inEditMode, id } =
    logicJumpFormItemValues;
  // The possible destinations should only be for Questions/End Screens
  // after the Crossroad Question.
  const startIndex = crossroadQuestionItem.item.index + 1;

  const selectOptions = generateSelectOptions({
    questions,
    endScreens,
    questionsStartIndex: startIndex,
    resolvedIndexes,
    includePlaceholderOption,
  });
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    initializeSelectedOption(selectOptions, selectedDestinationEndScreenId, selectedDestinationQuestionId),
  );

  const handleOnSelect = (option: SelectOption) => {
    const optionValue = option.value.toString();
    const destinationId = getDestinationIdFromSelectOptionValue(optionValue);
    if (optionValue.includes(DestinationType.END_SCREEN)) {
      onChange({
        selectedDestinationEndScreenId: destinationId,
        selectedDestinationQuestionId: undefined,
      });
    } else if (optionValue.includes(DestinationType.QUESTION)) {
      onChange({
        selectedDestinationQuestionId: destinationId,
        selectedDestinationEndScreenId: undefined,
      });
    } else if (optionValue === PLACEHOLDER_OPTION.value) {
      // If the placeholder option is selected and the ID is a number, it indicates
      // that the Logic Jump Rule had previously been created and is being removed
      // from its original destination.
      const isDeleted = typeof id === 'number';
      onChange({
        selectedDestinationQuestionId: undefined,
        selectedDestinationEndScreenId: undefined,
        deleted: isDeleted,
      });
    }
    setSelectedOption(option);
  };

  const disabled = !inEditMode || isDisabled;

  return (
    <div className={styles.container}>
      <ZCDSelect
        options={selectOptions}
        value={selectedOption}
        isDisabled={disabled}
        onChange={(newValue) => handleOnSelect(newValue as SelectOption)}
      />
    </div>
  );
};
