/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchLogicJumps } from 'services/logicJump';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { RequestStatus } from 'types/misc';

interface LogicJumpsPerCrossroadQuestion {
  [crossroadQuestionId: number]: {
    [logicJumpRuleId: number]: LogicJumpRuleBackoffice;
  };
}

interface LogicJumpsState {
  logicJumpsPerCrossroadQuestion: LogicJumpsPerCrossroadQuestion;
  fetchStatus: RequestStatus;
  errorMessage?: string;
}

const initialState: LogicJumpsState = {
  logicJumpsPerCrossroadQuestion: {},
  fetchStatus: RequestStatus.IDLE,
};

const logicJumpsSlice = createSlice({
  name: 'logicJumps',
  initialState,
  reducers: {
    setLogicJumpRules: (state: LogicJumpsState, action: PayloadAction<LogicJumpRuleBackoffice[]>) => {
      const logicJumpRules = action.payload;
      logicJumpRules.forEach((rule) => {
        const { crossroad_question: crossroadQuestionId, id } = rule;
        if (!(crossroadQuestionId in state.logicJumpsPerCrossroadQuestion)) {
          state.logicJumpsPerCrossroadQuestion[crossroadQuestionId] = {};
        }
        state.logicJumpsPerCrossroadQuestion[crossroadQuestionId][id] = rule;
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLogicJumps.pending, (state: LogicJumpsState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(fetchLogicJumps.rejected, (state: LogicJumpsState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      })
      .addCase(
        fetchLogicJumps.fulfilled,
        (state: LogicJumpsState, action: PayloadAction<LogicJumpRuleBackoffice[]>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          const logicJumps = action.payload;
          state.logicJumpsPerCrossroadQuestion = {};
          logicJumps.forEach((logicJump) => {
            const { crossroad_question: crossroadQuestionId, id } = logicJump;
            if (!(crossroadQuestionId in state.logicJumpsPerCrossroadQuestion)) {
              state.logicJumpsPerCrossroadQuestion[crossroadQuestionId] = {};
            }
            state.logicJumpsPerCrossroadQuestion[crossroadQuestionId][id] = logicJump;
          });
        },
      );
  },
});

export const { setLogicJumpRules } = logicJumpsSlice.actions;
export default logicJumpsSlice;
