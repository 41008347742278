import { ActionItem } from 'components/ManageDropdownButton/ActionItem';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { RouterPath, SurveyChildPath } from 'routerPaths';

interface Props {
  surveyId: string;
}

export const CloneSurveyActionItem = (props: Props): ReactElement => {
  const { surveyId } = props;
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleOnCloneClick = () => {
    const surveysCloneScreenPath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.CLONE}`, {
      surveyId,
    });

    navigate(surveysCloneScreenPath);
  };
  return <ActionItem iconName="copy" text={translate('surveyManage.clone')} onClick={handleOnCloneClick} />;
};
