import React, { ReactElement } from 'react';
import { HorizontalSeparator } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';
import { ActionButtonAsIcon } from 'components/ActionButtonAsIcon/ActionButtonAsIcon';
import { QuestionItem } from 'types/questions';
import { QuestionType } from 'types/genericQuestion';
import styles from './QuestionContextualToolbar.module.scss';

interface Props {
  questionItem: QuestionItem;
  isRootLevelQuestion: boolean;
  inEditMode: boolean;
  handleOnEditClick: () => void;
  handleOnDeleteClick: () => void;
  handleOnAddClick: (addQuestionInsideGroup: boolean) => void;
  handleOnAddEndScreenClick: () => void;
  resolvedPotentialNextIndex: string;
  resolvedPotentialNextChildIndex: string;
}

/**
 * Toolbar with buttons to edit, add, or delete a question in the "Build" page.
 */
export const QuestionContextualToolbar = (props: Props): ReactElement => {
  const {
    questionItem,
    isRootLevelQuestion,
    inEditMode,
    resolvedPotentialNextIndex,
    resolvedPotentialNextChildIndex,
    handleOnEditClick,
    handleOnDeleteClick,
    handleOnAddClick,
    handleOnAddEndScreenClick,
  } = props;
  const { t: translate } = useTranslation();

  const isGroupQuestion = questionItem.item.question_type === QuestionType.GROUP;
  const canAddQuestionInsideGroup = isGroupQuestion || !isRootLevelQuestion;

  const handleOnAddRootLevelQuestionClick = () => {
    handleOnAddClick(false);
  };

  const handleOnAddQuestionInsideGroupClick = () => {
    handleOnAddClick(true);
  };

  return (
    <div className={styles.questionContextualToolbar} style={{ marginLeft: isRootLevelQuestion ? '12px' : '-12px' }}>
      <ActionButtonAsIcon
        iconName="edit"
        handleOnButtonClick={handleOnEditClick}
        tooltipText={translate('common.edit')}
        isActive={inEditMode}
      />
      <ActionButtonAsIcon
        iconName="delete"
        handleOnButtonClick={handleOnDeleteClick}
        tooltipText={translate('common.delete')}
      />
      <HorizontalSeparator />
      <ActionButtonAsIcon
        iconName="add-item"
        handleOnButtonClick={handleOnAddRootLevelQuestionClick}
        tooltipText={translate('questionContextualToolbar.add', { resolvedPotentialNextIndex })}
      />
      {canAddQuestionInsideGroup && (
        <ActionButtonAsIcon
          iconName="add-sub-item"
          handleOnButtonClick={handleOnAddQuestionInsideGroupClick}
          tooltipText={`Add a new question to the group ("${resolvedPotentialNextChildIndex}")`}
        />
      )}
      <ActionButtonAsIcon
        iconName="more"
        handleOnButtonClick={handleOnAddEndScreenClick}
        tooltipText={translate('questionContextualToolbar.addEndScreen')}
      />
    </div>
  );
};
