/* eslint-disable max-lines */

import { SentimentScore } from '@zencity/survey-types';
import { Aspect } from 'types/aspect';
import {
  CommunitySurveyDemographicsScore,
  CommunitySurveyOverallScore,
  DemographicType,
} from 'types/communitySurveyScores';
import { Nullable } from 'types/misc';
import { Question } from 'types/questions';
import { ListEntry } from 'wordcloud';
import styles from '../WidgetGenerator.module.scss';
import { BenchmarkOverallScoreText } from '../widgets/BenchmarkWidgets/BenchmarkOverallSatisfactionText/types';

type ScorePerPeriod = {
  totalSubmissions: number;
  startDate: string;
  endDate: string;
  scores: SentimentScore;
};
export type AreasScoresData = {
  id: string;
  name: string;
  scoresPerPeriod: ScorePerPeriod[];
};
export interface AreasScoreRawData {
  [key: string]: AreasScoresData;
}

export interface CrossBreakdown {
  text: string;
  total_submissions: number;
  score: number;
  sort_index?: number;
}

export interface CrossChoiceData {
  text: string;
  numeric_value: Nullable<number>;
  choice_id: Nullable<number>;
  cross_generic_question_id: number;
  breakdown_by_cross: CrossBreakdown[];
}
export enum DemographicGeographicType {
  AGE = 'age',
  EDUCATION = 'education',
  INCOME = 'income',
  AREA = 'area',
}
export interface DemographicAndGeographicBreakdown {
  text: string;
  demographic_type: DemographicGeographicType;
  total_submissions: number;
  score: number;
}

export interface DemographicGeographicData {
  text: string;
  numeric_value: Nullable<number>;
  breakdown_by_cross: DemographicAndGeographicBreakdown[];
}

export interface CrossQuestionData {
  cross_choices_data: CrossChoiceData[];
  demographic_and_geographic_data: DemographicGeographicData[];
}

// widget types.
export interface WordCloudWidgetData {
  positive: ListEntry[];
  negative: ListEntry[];
  other?: ListEntry[];
}

export interface ClassificationSubTopicResponses {
  classificationSubTopicId: number;
  classificationSubTopicValue: string;
  classificationTopicId: number;
  classificationTopicValue: string;
  responses: number;
}

export type SentimentBarRowsWidgetData = {
  score: SentimentScore;
  scores: SentimentScore;
  text: string;
};

export type ScoreTextWithSentimentBarWidgetData = {
  score: SentimentScore;
  previousCycle?: {
    score: SentimentScore;
  };
  text: string;
  totalSubmissions?: number;
  emphasizedText?: string;
};

export type SentimentBarGroupsWidgetData = {
  groupItems: {
    score: SentimentScore;
    previousCycle?: {
      score: SentimentScore;
      totalSubmissions: number;
    };
    text: string;
    totalSubmissions?: number;
  }[];
  groupTitle: string;
};

export interface OverallSatisfactionScoreQuestion {
  genericQuestionId: number;
  score: SentimentScore;
  previousCycle?: { score: SentimentScore };
  text: string;
  aspectValue: string;
}

export interface AspectsData {
  score: {
    positive: number;
    negative: number;
    neutral: number;
  };
  description?: string;
  text?: string;
}

export interface OverallSentimentAspect extends AspectsData {
  previousCycle?: { score: SentimentScore };
}

export type OverallSentimentBarTableWidgetData = {
  qualityOfLifeLastCycleScores: OverallSatisfactionScoreQuestion[];
  characteristicsLastCycleScores: OverallSentimentAspect[];
};

export type AreasSentimentBarTableWidgetData = {
  id: string;
  name: string;
  totalSubmissions: number;
  score: SentimentScore;
  previousCycle?: { score: SentimentScore };
};

export type TableWrapperWidgetData = {
  [key: string]: string;
};

export type OverallSatisfactionData = {
  score: SentimentScore;
  totalSubmissions: number;
  previousCycle?: {
    score: SentimentScore;
    totalSubmissions: number;
  };
};
export type ResponseOption = {
  count: number;
  validCount: number;
  weightsSum: number;
  previousCycle?: {
    count: number;
    validCount: number;
    weightsSum: number;
  };
  segmentLabel?: string;
};
export type ChoiceData = {
  responseOptions: ResponseOption[];
  id: number;
  responseContent: string;
};
export type ChoicesQuestionChartData = {
  choices: ChoiceData[];
  total_responses: number;
  total_valid_responses: number;
  total_weights: number;
  previousCycle?: {
    total_responses: number;
    total_valid_responses: number;
    total_weights: number;
  };
};

export type MaintainAndFocusServicesWidgetData = {
  maintain: OverallSatisfactionScoreQuestion[];
  focus: OverallSatisfactionScoreQuestion[];
};

export type QuestionnaireWidgetData = {
  questions: Question[];
  aspect: Aspect;
};

export type CrossScore = {
  score: SentimentScore;
  totalSubmissions: number;
  previousCycle?: {
    score: SentimentScore;
    totalSubmissions: number;
  };
  sortIndex?: Nullable<number>;
};

export type CrossChoicesScore = Record<string, CrossScore>;

export type QuestionChoicesCross = Record<string, CrossChoicesScore[]>;

export type DemographicGeographicScore = Record<string, CrossScore>;

export type DemographicGeographicCross = {
  [DemographicGeographicType.AGE]?: DemographicGeographicScore[];
  [DemographicGeographicType.EDUCATION]?: DemographicGeographicScore[];
  [DemographicGeographicType.INCOME]?: DemographicGeographicScore[];
  [DemographicGeographicType.AREA]?: DemographicGeographicScore[];
};
export enum CrossTabsDataTypes {
  CROSS_QUESTIONS = 'crossQuestionsData',
  CROSS_DEMOGRAPHIC_GEOGRAPHIC = 'crossDemographicGeographicData',
  SCORE_TEXT_WITH_SENTIMENT_BAR = 'scoreTextWithSentimentBar',
}

export type CrossTabsWidgetData = {
  [CrossTabsDataTypes.CROSS_QUESTIONS]: QuestionChoicesCross[];
  [CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC]: DemographicGeographicCross[];
  [CrossTabsDataTypes.SCORE_TEXT_WITH_SENTIMENT_BAR]: ScoreTextWithSentimentBarWidgetData[];
};

export type CrossTabsQuestionsData =
  | CrossTabsWidgetData[CrossTabsDataTypes.CROSS_QUESTIONS]
  | CrossTabsWidgetData[CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC];

// utils types.
export enum AspectValue {
  CHARACTERISTIC = 'characteristic',
  QUALITY_OF_LIFE = 'quality_of_life',
  DEMOGRAPHICS = 'demographics',
}

export enum BarChartWidgetType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}
export type AspectValueType = 'quality_of_life' | 'characteristic';

export type ScoresPerPeriod = {
  totalSubmissions: number;
  period: string;
  scores: SentimentScore;
};

export enum WidgetType {
  SENTIMENT_BAR_AREAS_TABLE = 'sentiment_bar_areas_table',
  SERVICE_SENTIMENT_BAR = 'SERVICE_SENTIMENT_BAR',
  SENTIMENT_BAR = 'sentiment_bar',
  GENERAL_TABLE = 'general_table',
  WORD_CLOUD = 'word_cloud',
  DEFAULT = 'default',
  OVERALL_BAR_CHART = 'overall_bar_chart',
  SENTIMENT_BAR_OVERALL_TABLE = 'sentiment_bar_overall_table',
  TOPICS_BUBBLE_CHART = 'topics_bubble_chart',
  DEMOGRAPHICS = 'demographics',
  SERVICES_BAR_CHART = 'services_bar_chart',
  BENCHMARK_GENERIC_QUESTIONS_TABLE = 'benchmark_questions_table',
  BENCHMARK_OVERALL_SATISFACTION_TEXT = 'benchmark_overall_satisfaction_text',
  SENTIMENT_BAR_GROUPS = 'sentiment_bar_groups',
  OVERALL_SATISFACTION_TEXT = 'overall_satisfaction_text',
  CHOICES_QUESTION_CHART = 'choices_question_chart',
  MAINTAIN_AND_FOCUS_SERVICES = 'maintain_and_focus_services',
  QUESTIONNAIRE = 'questionnaire',
  CROSS_TABS = 'cross_tabs',
}

export const DEMOGRAPHIC_TYPE_TO_LABEL: Record<DemographicType, string> = {
  [DemographicType.AGE_GROUP]: 'Age',
  [DemographicType.GENDER]: 'Gender',
  [DemographicType.EDUCATION]: 'Education',
  [DemographicType.INCOME]: 'Income',
  [DemographicType.ETHNICITY]: 'Ethnicity',
  [DemographicType.ORIGIN]: 'Ethnicity',
};
export interface SelectedDateRange {
  value: string;
  startDate: string;
  endDate: string;
}

export enum BenchmarkColumnKey {
  NATIONAL = 'national',
  COHORT = 'cohort',
}

export enum WordCloudQuestionSentiment {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  OTHER = 'other',
}
export interface SurveyCycle {
  startDate: string;
  endDate: string;
  displayOnDashboard?: boolean;
}

export enum ChoicesQuestionChartDisplayType {
  PERCENTAGE = 'percentage',
  INTEGER = 'integer',
}

export const enum ChoicesQuestionChartDisplayOrder {
  APPEARANCE = 'appearance',
  POPULARITY = 'popularity',
}

interface BenchmarkTypeScore {
  [benchmarkType: string]: SentimentScore;
}

export interface BenchmarkGenericQuestionScore {
  generic_question_id: number;
  generic_question_text: string;
  aspect_value: string;
  overall_score: number;
  benchmark_scores: BenchmarkTypeScore;
}

export interface BenchmarkTextWidgetData {
  benchmarkOverallScores: BenchmarkOverallScore[];
  communitySurveyOverallScores: CommunitySurveyOverallScore[];
  overallScoreText: BenchmarkOverallScoreText;
}

export interface BenchmarkOverallScore {
  benchmark_type: string;
  start_date: string;
  end_date: string;
  scores: SentimentScore;
  total_submissions: number;
}

export const subTopicToExclude = ['miscellaneous'];

export interface SubTopic {
  subtopic: string;
  responses: number;
}

export interface Segment {
  label: string;
  segmentLabel?: string;
  colorClassName: string;
}

export type RawWidgetData =
  | string[]
  | WordCloudWidgetData
  | ClassificationSubTopicResponses[]
  | SentimentBarRowsWidgetData[]
  | CommunitySurveyDemographicsScore[]
  | ScoreTextWithSentimentBarWidgetData[]
  | SentimentBarGroupsWidgetData[]
  | OverallSatisfactionScoreQuestion[]
  | OverallSentimentBarTableWidgetData
  | AreasSentimentBarTableWidgetData[]
  | TableWrapperWidgetData[]
  | OverallSatisfactionData
  | ChoicesQuestionChartData
  | MaintainAndFocusServicesWidgetData
  | QuestionnaireWidgetData[]
  | CrossTabsWidgetData
  | BenchmarkGenericQuestionScore[]
  | BenchmarkTextWidgetData;

export const sentimentColor: Record<string, string> = {
  positive: styles.positiveColor,
  neutral: styles.neutralColor,
  negative: styles.negativeColor,
};
