import { ZCDButton, ZCDSelect, zcdNotify } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSurveyLanguage } from 'slices/surveyManage';
import { RootState } from 'store';
import { SurveyLanguage, SurveyStatus } from '@zencity/survey-types';
import { createNewSurveyLanguage } from 'services/surveyLanguage';
import styles from './SurveyLanguageSelect.module.scss';

export interface LanguageSelectOption {
  label: string;
  value: string;
}

interface Props {
  surveyLanguages: SurveyLanguage[];
}

/**
 * A selector for adding a language to a survey.
 */
export const SurveyLanguageSelect = (props: Props): ReactElement => {
  const { surveyLanguages } = props;
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageSelectOption | null>();
  const [isLoadingSurveyLanguageAddition, setIsLoadingSurveyLanguageAddition] = useState(false);
  const { languages } = useAppSelector((state: RootState) => state.globalConfigs);
  const { currentSurvey } = useAppSelector((state: RootState) => state.surveyManage);
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { id: currentSurveyId, status } = currentSurvey!;
  const isClosed = status === SurveyStatus.CLOSED;

  const handleOnAddLanguage = async () => {
    if (!selectedLanguage) {
      return;
    }

    setIsLoadingSurveyLanguageAddition(true);
    try {
      const newSurveyLanguage = await createNewSurveyLanguage(selectedLanguage.value, currentSurveyId);
      dispatch(setSurveyLanguage(newSurveyLanguage));
      setSelectedLanguage(null);
    } catch (error) {
      zcdNotify.error(`Failed to POST Survey Language for Survey #${currentSurveyId} (${selectedLanguage.value}).`);
    } finally {
      setIsLoadingSurveyLanguageAddition(false);
    }
  };

  const handleOnSelectChange = (selectedOption: unknown) => {
    const option = selectedOption as LanguageSelectOption;
    setSelectedLanguage(option);
  };

  const languageSelectOptions = useMemo(() => {
    const currentSurveyLanguages = surveyLanguages.map((surveyLanguage) => surveyLanguage.language);
    const possibleLanguages = Object.entries(languages).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([languageCode, languageValue]) => !currentSurveyLanguages.includes(languageCode),
    );
    const selectOptions = possibleLanguages.map(([languageCode, languageLabel]) => ({
      label: languageLabel,
      value: languageCode,
    }));
    return selectOptions;
  }, [surveyLanguages, languages]);

  return (
    <LoaderMask isLoading={isLoadingSurveyLanguageAddition}>
      {!isClosed && (
        <div className={styles.languageSelect}>
          <div className={styles.selectContainer}>
            <ZCDSelect options={languageSelectOptions} value={selectedLanguage} onChange={handleOnSelectChange} />
          </div>
          <ZCDButton text={translate('common.add')} variant="link" onClick={handleOnAddLanguage} />
        </div>
      )}
    </LoaderMask>
  );
};
