import { ZCDIconButton, zcdNotify } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
}

export const CopyTextToClipboardButton = ({ text }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const handleOnCopyToClipboard = () => {
    navigator.clipboard.writeText(text);
    zcdNotify.success(translate('toastMessages.copiedToClipboard'));
  };
  return <ZCDIconButton onClick={handleOnCopyToClipboard} icon="copy-transparent" variant="menu" type="button" />;
};
