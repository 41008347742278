import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyTextToClipboardButton } from 'components/CopyTextToClipboardButton/CopyTextToClipboardButton';
import { MarkdownHOC } from 'components/MarkdownHOC/MarkdownHOC';
import styles from './OverrideQuestion.module.scss';

interface ShowingOriginalProps {
  value?: string;
}
/**
 * Display a value with the "Original" prefix.
 *
 * The idea is to be able to display a layer of the original values
 * (i.e., the Generic Question's values), along with the form to override these
 * values.
 */
export const ShowingOriginalValue = ({ value }: ShowingOriginalProps): ReactElement => {
  const { t: translate } = useTranslation();

  const originalValue = value ?? '';

  return (
    <div className={styles.showingOriginal}>
      <div>
        <span>{translate('common.original')}: </span>
        <MarkdownHOC text={originalValue.length ? originalValue : translate('common.blank')} />
      </div>
      {originalValue && <CopyTextToClipboardButton text={originalValue} />}
    </div>
  );
};
