import { ZCDInput } from '@zencity/common-ui';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { useAppSelector } from 'customHooks/hooks';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { RootState } from 'store';
import { QuestionConfigType } from 'types/questionConfig';
import { Question } from 'types/questions';
import { findQuestionConfig } from 'utils/genericAndQuestionConfigs';

interface Props {
  logicJumpFormItem: LogicJumpFormItem;
  onChange: (updatedFormValues: LogicJumpFormItemChangeType) => void;
  selectedOriginQuestion: Question;
}

const initializeValue = (conditionValue?: number | string): number | undefined => {
  if (!conditionValue) {
    return undefined;
  }
  return parseInt(conditionValue.toString());
};

export const ScaleNumberInput = (props: Props): ReactElement => {
  const { logicJumpFormItem, onChange, selectedOriginQuestion } = props;
  const { conditionValue, inEditMode } = logicJumpFormItem;

  // This component can only render when there is a selected Origin Question,
  // so we can assert it as true.
  const { generic_question: genericQuestionId } = selectedOriginQuestion;

  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const genericQuestion = genericQuestionsById[genericQuestionId];

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState<number>();
  const [value, setValue] = useState<number | undefined>(initializeValue(conditionValue));

  useEffect(() => {
    if (!genericQuestion) {
      return;
    }

    const stepsConfig = findQuestionConfig(selectedOriginQuestion, genericQuestion, QuestionConfigType.STEPS);
    const startAtOneConfig = findQuestionConfig(
      selectedOriginQuestion,
      genericQuestion,
      QuestionConfigType.START_AT_ONE,
    );
    if (stepsConfig?.configValue) {
      setMaxValue(parseInt(stepsConfig.configValue));
    }
    if (startAtOneConfig) {
      setMinValue(1);
    }
  }, [selectedOriginQuestion, genericQuestion]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.currentTarget;
    const newNumericValue = parseInt(newValue ?? '');
    setValue(newNumericValue);
    onChange({
      conditionValue: newNumericValue,
    });
  };

  return (
    <ZCDInput
      type="number"
      disabled={!inEditMode}
      min={minValue}
      max={maxValue}
      value={value}
      onChange={handleOnChange}
    />
  );
};
