import { ZCDIconColor, zcdNotify } from '@zencity/common-ui';
import { ActionItem } from 'components/ManageDropdownButton/ActionItem';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { closeSurvey, fetchSurveyById } from 'services/survey';
import { addOrUpdateSurvey } from 'slices/survey';
import { setCurrentSurvey } from 'slices/surveyManage';
import { zcdDialogConfirm } from 'utils/dialog';
import { logger } from 'utils/logger';

interface Props {
  surveyId: number;
}

export const CloseSurveyActionItem = (props: Props): ReactElement => {
  const { surveyId } = props;
  const { t: translate } = useTranslation();

  const dispatch = useAppDispatch();

  const refetchSurvey = async () => {
    const updatedSurvey = await fetchSurveyById(surveyId);
    dispatch(addOrUpdateSurvey(updatedSurvey));
    dispatch(setCurrentSurvey(updatedSurvey));
  };

  const handleOnCloseClick = async () => {
    const userResponse = await zcdDialogConfirm({
      title: translate('closeSurveyDialog.header'),
      cancelButtonText: translate('closeSurveyDialog.closeButton'),
      message: translate('closeSurveyDialog.description'),
    });
    if (userResponse) {
      try {
        await closeSurvey(surveyId);
        await refetchSurvey();
      } catch (error) {
        zcdNotify.error(translate('toastMessages.serverRequestError'));
        logger.error(error);
      }
    }
  };

  return (
    <ActionItem
      iconName="close-large"
      text={translate('surveyManage.endSurvey')}
      color={ZCDIconColor.RED}
      onClick={handleOnCloseClick}
    />
  );
};
