import React, { useEffect, useRef } from 'react';
import { WordCloudWidgetData, WordCloudQuestionSentiment, sentimentColor } from 'screens/WidgetGenerator/utils/misc';
import WordCloud, { ListEntry } from 'wordcloud';

interface WordCloudProps {
  widgetData: WordCloudWidgetData;
  isValidData: boolean;
  width: number;
  height: number;
  sentimentDataTypes: WordCloudQuestionSentiment[];
}
const CANVAS_WIDTH = 520;
const CANVAS_HEIGHT = 220;
// This demoninator is a magic number that was found by trial and error to make the words fit in the canvas.
// It matches the value that is used in the Organic wordcloud.
const DEMONINATOR_FOR_MAX_WORD_SIZE = 5;
const MAX_WORD_SIZE = Math.sqrt(CANVAS_WIDTH * CANVAS_HEIGHT) / DEMONINATOR_FOR_MAX_WORD_SIZE;
const MIN_WORD_SIZE = 9;
const GRID_SIZE = 13;
const MAX_WORDS = 30;

export function WordCloudChart(props: WordCloudProps): React.ReactElement | null {
  const { widgetData, isValidData, width, height, sentimentDataTypes } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null as unknown as HTMLCanvasElement);

  useEffect(() => {
    if (isValidData) {
      const dataForChart: WordCloudWidgetData = {
        positive: widgetData[WordCloudQuestionSentiment.POSITIVE].slice(0, MAX_WORDS),
        negative: widgetData[WordCloudQuestionSentiment.NEGATIVE].slice(0, MAX_WORDS),
        other: widgetData[WordCloudQuestionSentiment.OTHER]?.slice(0, MAX_WORDS) ?? [],
      };
      let flatData: ListEntry[] = [];
      let colored = 0;
      sentimentDataTypes.forEach((type) => {
        if ([WordCloudQuestionSentiment.POSITIVE, WordCloudQuestionSentiment.NEGATIVE].includes(type)) {
          colored += 1;
        }
        flatData = flatData.concat(dataForChart[type] || []);
      });

      const wordCounts = flatData.map((word) => word[1]);

      if (wordCounts.length > 0) {
        const maxWordCount = wordCounts.reduce((value, maxValue) => Math.max(value, maxValue));
        const minWordCount = wordCounts.reduce((value, minValue) => Math.min(value, minValue));

        const weightFactor = (MAX_WORD_SIZE - MIN_WORD_SIZE) / Math.max(maxWordCount - minWordCount, 1);
        if (sentimentDataTypes.length > 0) {
          WordCloud(canvasRef.current, {
            list: flatData,
            shape: 'circle',
            color() {
              // eslint-disable-next-line prefer-rest-params
              const labelsArr = arguments[5];
              if (colored > 1 && labelsArr.includes(WordCloudQuestionSentiment.NEGATIVE)) {
                return sentimentColor.negative;
              }
              if (colored > 1 && labelsArr.includes(WordCloudQuestionSentiment.POSITIVE)) {
                return sentimentColor.positive;
              }
              return 'black';
            },
            backgroundColor: 'transparent',
            minRotation: 0,
            maxRotation: 0,
            drawOutOfBound: false,
            minSize: MIN_WORD_SIZE,
            gridSize: GRID_SIZE,
            shrinkToFit: true,
            weightFactor: (wordCount) => Math.round(MIN_WORD_SIZE + (wordCount - minWordCount) * weightFactor),
          });
        }
      }
    }

    return () => {
      WordCloud.stop();
    };
  }, [isValidData, widgetData, sentimentDataTypes]);

  // The width and height of the canvas are set to the width of the parent container minus `10px`
  // to account for the padding of the canvas and to show all of the words.
  return isValidData ? <canvas ref={canvasRef} width={width - 10} height={height - 10} /> : null;
}
