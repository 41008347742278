import React from 'react';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
} from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/constants';
import { GenericReactTable } from 'screens/WidgetGenerator/components/GenericReactTable/GenericReactTable';
import { chunkArray } from 'utils/misc';
import { v4 } from 'uuid';
import { CommunitySurveyDemographicsScore } from 'types/communitySurveyScores';
import { Flex } from 'components/Flex/Flex';
import { DEFAULT_MAX_ITEMS } from 'screens/WidgetGenerator/components/GenericReactTable/constants';
import { getDemographicLabel } from './utils';
import styles from './DemographicsTable.module.scss';

interface DemographicsProps {
  widgetData: CommunitySurveyDemographicsScore[];
  numRowsPerTable?: number;
}

export const DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE = 8;

export const DemographicsTable = (props: DemographicsProps): React.ReactElement => {
  const { widgetData, numRowsPerTable } = props;
  // filter data that has less than MIN_UNREPRESENTATIVE_INDICATOR submissions.
  const filteredData = widgetData.filter(
    (demographicScore) => (demographicScore.scores[0]?.total_submissions ?? 0) >= MIN_UNREPRESENTATIVE_INDICATOR,
  );
  const sortedData = filteredData
    .sort((demographicScore, prevDemographicScore) =>
      demographicScore.demographic_value?.localeCompare(prevDemographicScore.demographic_value),
    )
    .sort((demographicScore, prevDemographicScore) =>
      getDemographicLabel(demographicScore.demographic_type).localeCompare(
        getDemographicLabel(prevDemographicScore.demographic_type),
      ),
    );
  const chunkedData = chunkArray(sortedData, numRowsPerTable ?? DEFAULT_DEMOGRAPHIC_TABLE_ROWS_PER_TABLE);

  const getItemRow = (demographicScore: CommunitySurveyDemographicsScore) => {
    // Since the demographics score was requested for only 1 date range, only 1 score is expected to be returned.
    const selectedDemographicScore = demographicScore.scores[0] ?? {};
    if (!selectedDemographicScore) {
      return [];
    }
    const displayUnrepresentativeIndicator =
      selectedDemographicScore.total_submissions < MAX_UNREPRESENTATIVE_INDICATOR;

    return {
      demographic: <p className={styles.demographicLabel}>{getDemographicLabel(demographicScore.demographic_type)}</p>,
      group: <p className={styles.demographicGroup}>{demographicScore.demographic_label}</p>,
      score: `${selectedDemographicScore.scores.positive}% ${displayUnrepresentativeIndicator ? ' *' : ''}`,
      participants: selectedDemographicScore.total_submissions,
    };
  };

  const columns = [
    {
      Header: 'Demographic',
      accessor: 'demographic',
      width: 'auto',
    },
    {
      Header: 'Group',
      accessor: 'group',
      width: '236px',
    },
    {
      Header: 'Score',
      accessor: 'score',
      width: '43px',
    },
    {
      Header: 'N',
      accessor: 'participants',
      width: '35px',
    },
  ];

  const getBodyRows = ({
    rows,
    from,
    upTo,
  }: {
    rows: CommunitySurveyDemographicsScore[];
    from: number;
    upTo: number;
  }) => rows.slice(from, upTo).map((item) => getItemRow(item));

  return (
    <Flex alignItems="flex-start" className={styles.demographicsWidget}>
      {chunkedData.map((chunk) => {
        const tableData = getBodyRows({ rows: chunk, from: 0, upTo: chunk.length });

        return (
          <GenericReactTable
            key={v4()}
            columns={columns}
            tableData={tableData}
            maxItemsLimit={DEFAULT_MAX_ITEMS}
            condensed
          />
        );
      })}
    </Flex>
  );
};
