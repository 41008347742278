import { ZCDDialog, zcdNotify } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import {
  convertLogicJumpFormItemsToRules,
  groupFormItemRulesByRequestType,
} from 'components/LogicJumpDialog/LogicJumpDialog.helpers';
import { Footer } from 'components/LogicJumpDialog/components/Footer/Footer';
import { LogicJumpFormList } from 'components/LogicJumpDialog/components/LogicJumpFormList/LogicJumpFormList';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import { useLogicJumpFormItems } from 'customHooks/logicJumps';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createLogicJumpRule, deleteLogicJumpRule, fetchLogicJumps, updateLogicJumpRule } from 'services/logicJump';
import { writeQuestionConfig } from 'services/questions';
import { RootState } from 'store';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionItem: QuestionItem;
}

export const LogicJumpDialog = (props: Props): ReactElement => {
  const { isOpen, setIsOpen, questionItem } = props;
  const { t: translate } = useTranslation();

  const dispatch = useAppDispatch();
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);
  const { questions } = useAppSelector((state: RootState) => state.questions);

  const { logicJumpFormItems, setLogicJumpFormItems } = useLogicJumpFormItems(questionItem);
  const [isLoading, setIsLoading] = useState(false);

  const resolvedIndex = resolvedIndexes[questionItem.id];

  const closeDialog = () => {
    setIsOpen(false);
  };

  const setAllOriginQuestionsAsRequired = async () => {
    const allOriginQuestionIds = logicJumpFormItems.reduce<number[]>((originQuestionIds, formItem) => {
      if (!formItem.selectedOriginQuestionId || originQuestionIds.includes(formItem.selectedOriginQuestionId)) {
        return originQuestionIds;
      }
      return [...originQuestionIds, formItem.selectedOriginQuestionId];
    }, []);
    const requiredConfigDispatches = allOriginQuestionIds.map((originQuestionId) => {
      const payload = {
        questionId: originQuestionId,
        config_name: QuestionConfigType.REQUIRED,
        config_value: '1',
      };
      return dispatch(writeQuestionConfig(payload));
    });
    await Promise.all(requiredConfigDispatches).catch((error) => {
      logger.error(
        `Error setting Origin Questions as "required" for Logic Jumps on Crossroad Question #${questionItem.id}: ${error}`,
      );
    });
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      const logicJumpFormRules = convertLogicJumpFormItemsToRules(logicJumpFormItems, questions);
      const { postRules, deleteRules, patchRules } = groupFormItemRulesByRequestType(logicJumpFormRules);
      const allPostPromises = postRules.map<Promise<LogicJumpRuleBackoffice>>((rule) => createLogicJumpRule(rule));
      const allDeletePromises = deleteRules.map<Promise<void>>((rule) => deleteLogicJumpRule(rule.id as number));
      const allPatchPromises = patchRules.map<Promise<LogicJumpRuleBackoffice>>((rule) => updateLogicJumpRule(rule));
      await Promise.all(allPostPromises).catch((error) => {
        logger.error(error);
      });
      await Promise.all(allPatchPromises).catch((error) => {
        logger.error(error);
      });
      await Promise.all(allDeletePromises).catch((error) => {
        logger.error(error);
      });
      // Refetch logic jumps after all operations are finished to refresh the slice.
      await dispatch(fetchLogicJumps(questionItem.item.survey));
      await setAllOriginQuestionsAsRequired();
    } catch (error) {
      zcdNotify.error(error);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  const isEditModeInAnyForm = logicJumpFormItems.some((form) => form.inEditMode);

  return (
    <ZCDDialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      shouldCloseOnOverlayClick={false}
      header={<h1>{translate('logicJumpDialog.header', { resolvedIndex })}</h1>}
      footer={<Footer handleOnSubmit={handleOnSubmit} isValid={!isEditModeInAnyForm} closeDialog={closeDialog} />}
    >
      <LoaderMask isLoading={isLoading}>
        <LogicJumpFormList
          questionItem={questionItem}
          logicJumpFormItems={logicJumpFormItems}
          setLogicJumpFormItems={setLogicJumpFormItems}
        />
      </LoaderMask>
    </ZCDDialog>
  );
};
