import { LogicJumpFormItem } from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';

export const updateChangedLogicJumpFormItem = (
  logicJumpFormItems: LogicJumpFormItem[],
  changedLogicJumpFormItem: LogicJumpFormItem,
): LogicJumpFormItem[] => {
  const fallbackRule = logicJumpFormItems.find((item) => item.always);
  const newFormItemsWithoutFallbackRule = logicJumpFormItems.reduce<LogicJumpFormItem[]>(
    (newFormItems, item, index) => {
      if (item.always) {
        return newFormItems;
      }
      if (item.id === changedLogicJumpFormItem.id) {
        const isDeletedBeforeSaved =
          typeof changedLogicJumpFormItem.id === 'string' && changedLogicJumpFormItem.deleted;
        if (isDeletedBeforeSaved) {
          newFormItems.splice(index, 1);
          return newFormItems;
        }
        return [
          ...newFormItems,
          {
            ...changedLogicJumpFormItem,
            index,
          },
        ];
      }
      return [
        ...newFormItems,
        {
          ...item,
          index,
        },
      ];
    },
    [],
  );
  if (fallbackRule) {
    // The fallback rule should always be the last item in the list,
    // so we can just add it to the end of the list.
    if (fallbackRule.id === changedLogicJumpFormItem.id) {
      return [
        ...newFormItemsWithoutFallbackRule,
        {
          ...changedLogicJumpFormItem,
          index: newFormItemsWithoutFallbackRule.length,
        },
      ];
    }
    return [
      ...newFormItemsWithoutFallbackRule,
      {
        ...fallbackRule,
        index: newFormItemsWithoutFallbackRule.length,
      },
    ];
  }
  return newFormItemsWithoutFallbackRule;
};
