import { createAsyncThunk } from '@reduxjs/toolkit';
import { SurveyLanguage } from '@zencity/survey-types';
import { PaginatedResults } from 'types/misc';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/survey_language/');
const axiosInstance = createAxiosInstance(apiUrl);

export const fetchSurveyLanguagesBySurveyId = createAsyncThunk(
  'surveyManage/fetchSurveyLanguagesBySurveyId',
  async (surveyId: number) => {
    const response = await axiosInstance.get<PaginatedResults<SurveyLanguage>>('', {
      params: {
        bypass_client_filter: true,
        page_size: 10,
        survey: surveyId,
      },
    });
    return response.data.results;
  },
);

export const publishSurveyLanguage = async (surveyLanguageId: number): Promise<SurveyLanguage> => {
  const response = await axiosInstance.post(`${surveyLanguageId}/publish/`);
  return response.data;
};

export const createNewSurveyLanguage = async (language: string, surveyId: number): Promise<SurveyLanguage> => {
  const response = await axiosInstance.post('', {
    survey: surveyId,
    language,
  });
  return response.data;
};
