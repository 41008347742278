import { QuestionConfigType } from 'types/questionConfig';

/**
 * Return the config name with capitilzation and from underscore_separated to space separated.
 */
export const formatConfigName = (configName: QuestionConfigType): string =>
  configName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const resolveConfigValue = (configName: QuestionConfigType, configValue: string): string | undefined => {
  if (configValue === '1') {
    // Return the config name with capitilzation and from underscore_separated to space separated.
    return formatConfigName(configName);
  }
  if (configValue === '0') {
    return undefined;
  }
  return `${formatConfigName(configName)}: "${configValue}"`;
};
