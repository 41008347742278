import { ZCDButton, zcdNotify } from '@zencity/common-ui';
import { useAppDispatch } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentSurvey, setSurveyLanguage } from 'slices/surveyManage';
import { logger } from 'utils/logger';
import { SurveyLanguage } from '@zencity/survey-types';
import { publishSurveyLanguage } from 'services/surveyLanguage';
import { addOrUpdateSurvey } from 'slices/survey';
import { fetchSurveyById } from 'services/survey';
import styles from './SurveyLanguagePublishButton.module.scss';

interface Props {
  surveyLanguage: SurveyLanguage;
}

/**
 * Handles the publishing of a Survey Language.
 */
export const SurveyLanguagePublishButton = (props: Props): ReactElement => {
  const { surveyLanguage } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const { id: surveyLanguageId, language } = surveyLanguage;

  const refetchSurvey = async () => {
    const updatedSurvey = await fetchSurveyById(surveyLanguage.survey);
    dispatch(addOrUpdateSurvey(updatedSurvey));
    dispatch(setCurrentSurvey(updatedSurvey));
  };

  const publishNewSurveyLanguage = async () => {
    try {
      const updatedSurveyLanguage = await publishSurveyLanguage(surveyLanguageId);
      dispatch(setSurveyLanguage(updatedSurveyLanguage));
      // If successful, the Survey's status should be updated to "published".
      await refetchSurvey();
    } catch (error) {
      const asError = error as Error;

      // Determine the specific error message for user display.
      if (asError.message.includes('census_data')) {
        zcdNotify.error('Before publishing a Survey Language, make sure there is a Census Data for the client.');
      } else {
        zcdNotify.error(asError.message);
        logger.warn(`Failed publishing Survey Language #${surveyLanguageId} (${language}): ${error}`);
      }
    } finally {
      setIsPublishLoading(false);
    }
  };

  const handleOnPublishButtonClick = (
    event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLInputElement, MouseEvent>,
  ) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setIsPublishLoading(true);
    publishNewSurveyLanguage();
  };

  return (
    <div className={styles.container}>
      <ZCDButton
        type="button"
        size="md"
        variant="primary"
        loading={isPublishLoading}
        text={translate('common.publish')}
        onClick={handleOnPublishButtonClick}
      />
    </div>
  );
};
