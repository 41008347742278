import React, { ReactElement } from 'react';
import { ZCDDialog } from '@zencity/common-ui';
import { QuestionItem } from 'types/questions';
import { ConfigsList } from 'components/QuestionInformationDialog/components/ConfigsList/ConfigsList';
import { Flex } from 'components/Flex/Flex';
import { useAppSelector } from 'customHooks/hooks';
import { RootState } from 'store';

import { LogicJumpFormItemsList } from 'components/QuestionInformationDialog/components/LogicJumpFormItemsList';
import { useTranslation } from 'react-i18next';

interface Props {
  questionItem: QuestionItem;
  resolvedIndex: string;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Displays various information about a Question.
 */
export const QuestionInformationDialog = (props: Props): ReactElement => {
  const { isOpen, onClose, questionItem, resolvedIndex } = props;
  const { t: translate } = useTranslation();
  const { genericQuestionsById } = useAppSelector((state: RootState) => state.genericQuestions);
  const genericQuestion = genericQuestionsById[questionItem.item.generic_question];

  const { configs } = questionItem.item;

  return (
    <ZCDDialog isOpen={isOpen} onRequestClose={onClose}>
      <Flex flexDirection="column" gap="24px">
        <h1>
          {translate('questionInformationDialog.title', {
            resolvedIndex,
          })}
        </h1>
        <h3>
          {translate('questionInformationDialog.id', {
            id: questionItem.item.id,
          })}
        </h3>
        <ConfigsList configs={configs} genericQuestion={genericQuestion} />
        <LogicJumpFormItemsList questionItem={questionItem} />
      </Flex>
    </ZCDDialog>
  );
};
