import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericReactTable } from 'screens/WidgetGenerator/components/GenericReactTable/GenericReactTable';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
} from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/constants';
import { Column } from 'react-table';
import { UnrepresentativeIndicator } from 'screens/WidgetGenerator/components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import {
  AreasSentimentBarTableWidgetData,
  OverallSentimentAspect,
  OverallSatisfactionScoreQuestion,
} from 'screens/WidgetGenerator/utils/misc';
import { TableSentiment } from './TableSentiment';
import { TableText } from './TableText';

interface SentimentBarTableProps {
  firstColumHeader: string;
  secondColumHeader: string;
  columnsWidths?: (number | string)[];
  isBenchmarked?: boolean;
  condensed?: boolean;
}

interface AreasProps extends SentimentBarTableProps {
  widgetData: AreasSentimentBarTableWidgetData[];
  type: 'areas';
}

interface OverallProps extends SentimentBarTableProps {
  widgetData: (OverallSatisfactionScoreQuestion | OverallSentimentAspect)[];
  type: 'overall';
}
interface TableRowData {
  textColumn: ReactElement;
  scoreColumn: ReactElement;
}

export function SentimentBarTable(props: AreasProps | OverallProps): React.ReactElement {
  const { type, widgetData, firstColumHeader, secondColumHeader, columnsWidths, isBenchmarked, condensed } = props;
  const { t: translate } = useTranslation();

  let tableBodyRows = [];

  const columns: Column[] = [
    {
      Header: firstColumHeader,
      accessor: 'textColumn',
    },
    {
      Header: secondColumHeader,
      accessor: 'scoreColumn',
    },
  ];
  columnsWidths?.forEach((width, index) => {
    columns[index].width = width;
  });

  if (type === 'areas') {
    tableBodyRows = widgetData.map((item): TableRowData => {
      const { score, totalSubmissions } = item;
      const prevPositiveValue =
        isBenchmarked && 'previousCycle' in item ? item.previousCycle?.score?.positive : undefined;

      const displayUnrepresentativeIndicator =
        totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR && totalSubmissions > MIN_UNREPRESENTATIVE_INDICATOR;
      const displayNoScore = totalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR;

      return {
        textColumn: (
          <TableText
            text={item.name}
            totalSubmissions={totalSubmissions}
            displayUnrepresentativeIndicator={displayUnrepresentativeIndicator}
          />
        ),
        scoreColumn: displayNoScore ? (
          <Flex alignItems="center">
            {translate('sentimentBarTable.noScore')}
            <Spacer orientation="horizontal" size="xsmall" />
            <UnrepresentativeIndicator totalSubmissions={totalSubmissions} />
          </Flex>
        ) : (
          <TableSentiment score={score} prevPositiveValue={prevPositiveValue} isBenchmarked={isBenchmarked} />
        ),
      };
    });
  } else {
    tableBodyRows = widgetData.map((item): TableRowData => {
      const { score } = item;
      const prevPositiveValue =
        isBenchmarked && 'previousCycle' in item ? item.previousCycle?.score.positive : undefined;
      const text = item.text ?? '';
      return {
        textColumn: <TableText text={text} />,
        scoreColumn: (
          <TableSentiment score={score} prevPositiveValue={prevPositiveValue} isBenchmarked={isBenchmarked} />
        ),
      };
    });
  }

  return (
    <div className={`SentimentBarTable ${isBenchmarked && 'benchmarked'}`}>
      <GenericReactTable columns={columns} tableData={tableBodyRows} condensed={condensed} />
    </div>
  );
}
