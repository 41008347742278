import { ZCDButton, ZCDCard } from '@zencity/common-ui';
import { useOutsideClickListener } from 'customHooks/hooks';
import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Survey } from 'types/survey';
import { SurveyStatus } from '@zencity/survey-types';
import { CloneSurveyActionItem } from 'components/ManageDropdownButton/ActionItems/CloneSurveyActionItem';
import { EditSurveyActionItem } from 'components/ManageDropdownButton/ActionItems/EditSurveyActionItem';
import { CloseSurveyActionItem } from 'components/ManageDropdownButton/ActionItems/CloseSurveyActionItem';
import { RepublishSurveyActionItem } from 'components/ManageDropdownButton/ActionItems/RepublishSurveyActionItem';
import { ShowShareLinksActionItem } from 'components/ManageDropdownButton/ActionItems/ShowShareLinksActionItem';
import { ShareLinksDialog } from 'components/ShareLinksDialog/ShareLinksDialog';
import styles from './ManageDropdownButton.module.scss';

interface Props {
  survey: Survey;
}

/**
 * Displayed on the Manage screen, this button will display a list of actions for managing a survey.
 */
export const ManageDropdownButton = (props: Props): ReactElement => {
  const { survey } = props;
  const surveyIdAsString = survey.id.toString();

  const { t: translate } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [shareLinksDialogIsOpen, setShareLinksDialogIsOpen] = useState(false);

  const isPublished = survey.status === SurveyStatus.PUBLISHED;
  const isClosed = survey.status === SurveyStatus.CLOSED;
  const isLocked = survey.is_locked;

  const closeDropdown = () => {
    setShowDropdown(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickListener(wrapperRef, closeDropdown);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOnManageButtonClick = () => {
    toggleDropdown();
  };

  const handleOnShareLinksClick = () => {
    setShareLinksDialogIsOpen(true);
  };

  return (
    <>
      <div className={styles.manageDropdownWrapper} ref={wrapperRef}>
        <div className={styles.manageButton}>
          <ZCDButton
            variant="secondary"
            onClick={handleOnManageButtonClick}
            postIcon="chevron-down"
            text={translate('surveyManage.manage')}
          />
        </div>
        {showDropdown && (
          <div className={styles.actionsDropdown}>
            <ZCDCard elevated>
              {/* Always add the "Clone" button for all surveys. */}
              <CloneSurveyActionItem surveyId={surveyIdAsString} />
              {!isLocked && !isClosed && <EditSurveyActionItem surveyId={surveyIdAsString} />}
              {isPublished && <RepublishSurveyActionItem surveyId={survey.id} closeDropdown={closeDropdown} />}
              {isPublished && <ShowShareLinksActionItem onClick={handleOnShareLinksClick} />}
              {isPublished && <CloseSurveyActionItem surveyId={survey.id} />}
            </ZCDCard>
          </div>
        )}
      </div>
      <ShareLinksDialog isOpen={shareLinksDialogIsOpen} setIsOpen={setShareLinksDialogIsOpen} />
    </>
  );
};
