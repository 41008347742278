import { VerticalListWithSeparators, zcdNotify } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { SurveyLanguageSelect } from 'components/SurveyLanguageSelect/SurveyLanguageSelect';
import { SurveyLanguageItem } from 'components/SurveyLanguageItem/SurveyLanguageItem';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { fetchEndScreensBySurveyId } from 'services/endScreen';
import { fetchGenericQuestionsByIds } from 'services/genericQuestion';
import { fetchQuestionsBySurveyId } from 'services/questions';
import { fetchSurveyById } from 'services/survey';
import { updateGenericQuestions } from 'slices/genericQuestion';
import { initialState as globalConfigInitialState } from 'slices/globalConfig';
import { addOrUpdateSurvey } from 'slices/survey';
import { setCurrentLanguage, setSelectedSurveyLanguage } from 'slices/surveyManage';
import { RootState } from 'store';
import { Question } from 'types/questions';
import { logger } from 'utils/logger';
import { updateTranslationSections } from 'utils/surveyManage';
import { useTranslation } from 'react-i18next';
import styles from './SurveyManageList.module.scss';

interface Props {
  surveyId: number;
}

/**
 * The language menu for the Survey Manage screen. A user can select different languages
 * to add for a given survey to add translations and publish a Survey Language.
 */
export const SurveyManageList = (props: Props): ReactElement => {
  const { surveyId } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { surveyLanguagesById } = useAppSelector((state: RootState) => state.surveyManage);
  const { defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const [translationsLoading, setTranslationsLoading] = useState(false);

  /**
   * Fetches translations for a survey, it's questions,
   * and the relevant question's generic questions. Once they are fetched,
   * the Translation Sections are updated to reflect the fetched translations.
   */
  const fetchTranslations = (languages: string) => {
    try {
      // Fetch Questions for its `overridden_text`/`configs`.
      // The questions need to be fetched first, since they are required for fetching
      // the generic questions.
      dispatch(fetchQuestionsBySurveyId({ survey: surveyId, translations_languages: languages })).then(
        async (action: { payload: { results: Question[] } }) => {
          await dispatch(fetchEndScreensBySurveyId({ survey: surveyId, translations_languages: languages }));
          const translatedQuestions = action.payload.results as Question[];
          // Fetch the Survey, because it returns the SurveyGroup with its
          // `title_for_display`.
          const survey = await fetchSurveyById(surveyId, { translations_languages: languages });
          dispatch(addOrUpdateSurvey(survey));

          // Fetch GenericQuestions for its `text`/`choices`/`configs`.
          const genericQuestionIds = translatedQuestions.map((instance) => instance.generic_question);
          const response = await fetchGenericQuestionsByIds(genericQuestionIds, languages);
          dispatch(updateGenericQuestions(response.results));
          dispatch(updateTranslationSections(surveyId));
          setTranslationsLoading(false);
        },
      );
    } catch (error) {
      zcdNotify.error(translate('toastMessages.serverRequestError'));
      logger.error(error);
    }
  };

  useEffect(
    () => () => {
      dispatch(setSelectedSurveyLanguage(undefined));
      dispatch(setCurrentLanguage(defaultLanguage));
    },
    [defaultLanguage, dispatch],
  );

  /**
   * Fetch the translations whenever the list of SurveyLanguage instances gets
   * updated.
   */
  useEffect(() => {
    const languages = Object.values(surveyLanguagesById)
      .map((instance) => instance.language)
      .join(',');
    if (!languages || languages === globalConfigInitialState.defaultLanguage) {
      // No need to fetch any translation.
      return;
    }
    setTranslationsLoading(true);
    fetchTranslations(languages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyLanguagesById]);

  return (
    <div className={styles.surveyManageList}>
      <LoaderMask isLoading={translationsLoading}>
        <VerticalListWithSeparators
          className={styles.surveyLanguagesList}
          removeLastChildBottomSeparator={false}
          removeFirstChildTopSeparator={false}
        >
          {Object.values(surveyLanguagesById).map((surveyLanguage) => (
            <SurveyLanguageItem key={`survey-language-${surveyLanguage.id}`} surveyLanguage={surveyLanguage} />
          ))}
        </VerticalListWithSeparators>
      </LoaderMask>
      <SurveyLanguageSelect surveyLanguages={Object.values(surveyLanguagesById)} />
    </div>
  );
};
