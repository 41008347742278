import { Flex } from 'components/Flex/Flex';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { DestinationQuestionEndScreenSelect } from 'components/LogicJumpDialog/components/LogicJumpForm/components/DestinationQuestionEndScreenSelect/DestinationQuestionEndScreenSelect';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionItem } from 'types/questions';

interface Props {
  crossroadQuestionItem: QuestionItem;
  logicJumpFormItemValues: Pick<
    LogicJumpFormItem,
    'selectedDestinationEndScreenId' | 'selectedDestinationQuestionId' | 'id'
  >;
  handleOnSetFallbackRule: (
    selectedDestinationEndScreenId?: number,
    selectdDestinationQuestionId?: number,
    deleted?: boolean,
  ) => void;
  isDisabled?: boolean;
}

export const FallbackRule = (props: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const { logicJumpFormItemValues, handleOnSetFallbackRule, crossroadQuestionItem, isDisabled = false } = props;

  const handleOnChangeFormData = (updatedFormValues: LogicJumpFormItemChangeType) => {
    const { selectedDestinationEndScreenId, selectedDestinationQuestionId, deleted } = updatedFormValues;
    // Since the Fallback Rule has a placeholder option, we need to check if the user has selected a value or not.
    const isNotDefined = !selectedDestinationEndScreenId && !selectedDestinationQuestionId && !deleted;
    if (isNotDefined) {
      return;
    }
    handleOnSetFallbackRule(selectedDestinationEndScreenId, selectedDestinationQuestionId, deleted);
  };

  return (
    <Flex width="100%" gap="12px">
      <span>{translate('logicJumpDialog.fallbackRuleLabel')}</span>
      <DestinationQuestionEndScreenSelect
        logicJumpFormItemValues={{
          selectedDestinationEndScreenId: logicJumpFormItemValues.selectedDestinationEndScreenId,
          selectedDestinationQuestionId: logicJumpFormItemValues.selectedDestinationQuestionId,
          crossroadQuestionItem,
          inEditMode: true,
          id: logicJumpFormItemValues.id,
        }}
        onChange={handleOnChangeFormData}
        includePlaceholderOption
        isDisabled={isDisabled}
      />
    </Flex>
  );
};
