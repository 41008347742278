import { EditorState, convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

export const convertMarkdownTextToTextAreaEvent = (text: string): React.ChangeEvent<HTMLTextAreaElement> => {
  const textAreaEvent = {
    currentTarget: {
      value: text,
    },
    target: {
      value: text,
    },
  };
  return textAreaEvent as React.ChangeEvent<HTMLTextAreaElement>;
};

export const convertEditorStateToMarkdown = (editorState: EditorState): string => {
  const markdownString = draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
    preserveNewlines: true,
    escapeMarkdownCharacters: false,
  });

  return markdownString;
};
