import { ZCDButton, ZCDDialog, ZCDFormGroup, ZCDSelect } from '@zencity/common-ui';
import { useAppSelector } from 'customHooks/hooks';
import i18next from 'i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { RootState } from 'store';
import { Header } from './Header';
import { SharingLink } from './SharingLink';
import styles from './ShareLinksDialog.module.scss';

interface DialogLink {
  name: string;
  source: string;
  panelParam?: string;
}

const PREVIEW_LINK_QUERY_PARAM = 'previewLink=true';

export const PUBLIC_LINKS: DialogLink[] = [
  {
    name: i18next.t('shareLinksDialog.selfDistribution'),
    source: 'sd',
  },
  {
    name: i18next.t('shareLinksDialog.facebook'),
    source: 'fb',
  },
  {
    name: i18next.t('shareLinksDialog.instagram'),
    source: 'ig',
  },
  {
    name: i18next.t('shareLinksDialog.email'),
    source: 'email',
  },
  {
    name: i18next.t('shareLinksDialog.sms'),
    source: 'sms',
  },
  {
    name: i18next.t('shareLinksDialog.nextdoor'),
    source: 'nd',
  },
  {
    name: i18next.t('shareLinksDialog.tap'),
    source: 'tap',
    panelParam: 'tid={ID}',
  },
  {
    name: i18next.t('shareLinksDialog.pureSpectrum'),
    source: 'ps',
    panelParam: 'tid=[transaction_id]',
  },
];

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
}

/**
 * A dialog to display the links to be shared.
 */
export const ShareLinksDialog: React.FC<Props> = function ShareLinksDialog({
  isOpen = false,
  setIsOpen,
}: Props): ReactElement {
  const { t: translate } = useTranslation();
  const { currentSurvey, surveyLanguagesById } = useAppSelector((state) => state.surveyManage);
  const { defaultLanguage, languages } = useAppSelector((state: RootState) => state.globalConfigs);
  const [languageSelectOptions, setLanguageSelectOptions] = useState<SelectOption[]>([]);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<SelectOption>();
  const publicLink = currentSurvey?.survey_group?.survey_public_link;

  const closeDialog = () => setIsOpen(false);

  useEffect(() => {
    const selectOptions = Object.values(surveyLanguagesById).map<SelectOption>((surveyLanguage) => ({
      value: surveyLanguage.language,
      label: languages[surveyLanguage.language],
    }));
    setLanguageSelectOptions(selectOptions);
    const defaultSelectOption = selectOptions.find((option) => option.value === defaultLanguage);
    setSelectedLanguageOption(defaultSelectOption);
  }, [surveyLanguagesById, defaultLanguage, languages]);

  const handleOnLanguageSelect = (selectOption: SelectOption) => {
    setSelectedLanguageOption(selectOption);
  };

  const selectedLanguageValue = selectedLanguageOption ? selectedLanguageOption.value : '';
  const previewLinkUrl = `${publicLink}${selectedLanguageValue}?${PREVIEW_LINK_QUERY_PARAM}`;

  if (!publicLink) {
    return <div>{translate('shareLinksDialog.noPublicLink')}</div>;
  }

  return (
    <ZCDDialog
      isOpen={isOpen}
      onRequestClose={closeDialog}
      shouldCloseOnOverlayClick={false}
      header={<Header />}
      footer={<ZCDButton text={translate('common.done')} onClick={closeDialog} />}
    >
      <ZCDFormGroup>
        <label htmlFor="languageSelect">{translate('shareLinksDialog.selectLanguage')}</label>
        <ZCDSelect
          options={languageSelectOptions}
          onChange={(newValue) => handleOnLanguageSelect(newValue as SelectOption)}
          value={selectedLanguageOption}
        />
      </ZCDFormGroup>
      <div className={styles.linksContainer}>
        <SharingLink
          name={i18next.t('shareLinksDialog.previewLink')}
          linkUrl={previewLinkUrl}
          color={styles.zcdRed90}
        />
        {PUBLIC_LINKS.map((link) => {
          const panelParam = link.panelParam ? `&${link.panelParam}` : '';
          const linkUrl = `${publicLink}${selectedLanguageValue}?s=${link.source}${panelParam}`;
          return <SharingLink key={link.source} name={link.name} linkUrl={linkUrl} />;
        })}
      </div>
    </ZCDDialog>
  );
};
