import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'types/genericQuestion';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import { deleteQuestion, updateIndexes } from 'services/questions';
import { RootState } from 'store';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { deleteLogicJumpRule, fetchLogicJumps } from 'services/logicJump';
import { filterLogicJumpRulesForQuestion } from 'components/QuestionDeleteDialog/QuestionDeleteDialog.helpers';
import styles from './QuestionDeleteDialog.module.scss';

interface Props {
  questionItem: QuestionItem;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QuestionDeleteDialog: React.FC<Props> = function QuestionDeleteDialog({
  questionItem,
  isOpen,
  setIsOpen,
}: Props): ReactElement {
  const { toastError } = useContext(SurveyManagerToastContext);
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { logicJumpsPerCrossroadQuestion } = useAppSelector((state: RootState) => state.logicJumps);
  const allLogicJumps = Object.values(logicJumpsPerCrossroadQuestion)
    .map((crossroadQuestionLogicJumps) => Object.values(crossroadQuestionLogicJumps))
    .flat() as LogicJumpRuleBackoffice[];

  const submitHandler = () => {
    try {
      if (questionItem.id) {
        const { survey } = questionItem.item;
        const childrenIds = questionItem.children.map((child) => child.id);
        const logicJumpsToDelete = filterLogicJumpRulesForQuestion(questionItem.id, allLogicJumps);
        const allLogicJumpDeletePromises = logicJumpsToDelete.map((logicJump) => deleteLogicJumpRule(logicJump.id));
        Promise.all(allLogicJumpDeletePromises).then(() => {
          dispatch(deleteQuestion({ questionId: questionItem.id, childrenIds }));
          dispatch(updateIndexes());
          // Refetch logic jumps after deletions are finished to update the slice.
          dispatch(fetchLogicJumps(survey));
        });
      }
    } catch (error) {
      toastError();
      logger.error(error);
    }
  };
  const isGroup = questionItem.item?.question_type === QuestionType.GROUP;
  const title = isGroup
    ? translate('questionContextualToolbar.questionDeleteDialog.groupTitle')
    : translate('questionContextualToolbar.questionDeleteDialog.title');
  const content = isGroup
    ? translate('questionContextualToolbar.questionDeleteDialog.groupContent')
    : translate('questionContextualToolbar.questionDeleteDialog.content');

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      content={content}
      submitBtnLabel={translate('questionContextualToolbar.questionDeleteDialog.approveLabel')}
      confirmationHandler={submitHandler}
    >
      <ul>
        <li className={styles.question}>{questionItem.item?.text}</li>
        {questionItem.children?.length > 0 && (
          <ul className={styles.list}>
            {questionItem.children.map((child) => (
              <li key={`child-question-${child.id}`} className={styles.question}>
                {child.item?.text}
              </li>
            ))}
          </ul>
        )}
      </ul>
    </ConfirmationDialog>
  );
};
