import { ZCDButton } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  disabled: boolean;
}

export const AddRuleButton = (props: Props): ReactElement => {
  const { onClick, disabled } = props;
  const { t: translate } = useTranslation();
  return (
    <ZCDButton
      disabled={disabled}
      text={translate('logicJumpDialog.addRule')}
      onClick={onClick}
      variant="link"
      preIcon="more"
    />
  );
};
