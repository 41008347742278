import React from 'react';
import { Flex } from 'components/Flex/Flex';
import { capitalizeString } from 'utils/misc';
import { BarChartWidgetType, OverallSatisfactionData, sentimentColor } from 'screens/WidgetGenerator/utils/misc';
import { v4 } from 'uuid';
import { BarChart, LabelColor } from '../BarChart';
import styles from './OverallBarChart.module.scss';

interface OverallBarChartProps {
  widgetData: OverallSatisfactionData;
  isLabeled: boolean;
  isBenchmarked: boolean;
}

enum Sentiment {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

export function OverallBarChart(props: OverallBarChartProps): React.ReactElement {
  const { widgetData, isLabeled, isBenchmarked } = props;
  const { score, previousCycle } = widgetData;

  return (
    <Flex alignItems="flex-end" className={styles.overallBarChart}>
      {score &&
        Object.entries(score)?.map(([sentiment, scoreValue]) => {
          const barText = capitalizeString({ string: sentiment });
          const benchmarkScore = previousCycle ? previousCycle.score : undefined;
          const benchmarkSentimentScore = benchmarkScore?.[sentiment as Sentiment];
          const labelColor = sentiment === Sentiment.NEUTRAL ? LabelColor.BLACK : LabelColor.WHITE;

          return (
            <BarChart
              score={scoreValue}
              isLabeled={isLabeled}
              isShortBarChart={false}
              fillColor={sentimentColor[sentiment]}
              labelColor={labelColor}
              label={barText}
              showSpan={false}
              key={v4()}
              isBenchmarked={isBenchmarked}
              benchmarkScore={benchmarkScore && benchmarkSentimentScore}
              widgetType={BarChartWidgetType.SINGLE}
              wideBars
            />
          );
        })}
    </Flex>
  );
}
