import { LogicJumpFormItem } from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { useAppSelector } from 'customHooks/hooks';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { QuestionItem } from 'types/questions';

export const mapLogicJumpRulesToFormItems = (
  crossroadQuestionItem: QuestionItem,
  logicJumpRules: LogicJumpRuleBackoffice[],
): LogicJumpFormItem[] =>
  logicJumpRules
    .map<LogicJumpFormItem>((logicJumpRule) => {
      const { id, expression, destination_end_screen, destination_question, always, index } = logicJumpRule;
      // For now, it is only expected to use expressions with `conditions`.
      // This will change when multi-select questions
      // and AND conjunctions are supported.
      if (!('conditions' in expression)) {
        throw new Error('Only `conditions` expressions are supported for LogicJumpExpressions.');
      }

      // For now, it is expected that there is only 1 condition per expression.
      // This will change when multi-select questions
      // and AND conjunctions are supported.
      const { conditions } = expression;
      const condition = conditions[0] ?? {};

      const { operand_a_origin, operand_b, operator } = condition;
      return {
        id,
        crossroadQuestionItem,
        selectedDestinationEndScreenId: destination_end_screen ?? undefined,
        selectedDestinationQuestionId: destination_question ?? undefined,
        selectedOriginQuestionId: operand_a_origin?.origin_question_id,
        index,
        conditionValue: operand_b ?? undefined,
        operator,
        inEditMode: false,
        deleted: false,
        always,
      };
    })
    .sort((first, second) => first.index - second.index);

interface LogicJumpFormItemsHook {
  logicJumpFormItems: LogicJumpFormItem[];
  setLogicJumpFormItems: React.Dispatch<React.SetStateAction<LogicJumpFormItem[]>>;
}

export const useLogicJumpFormItems = (questionItem: QuestionItem): LogicJumpFormItemsHook => {
  const { logicJumpsPerCrossroadQuestion } = useAppSelector((state: RootState) => state.logicJumps);
  const [logicJumpFormItems, setLogicJumpFormItems] = useState<LogicJumpFormItem[]>([]);

  useEffect(() => {
    const crossroadQuestionLogicJumps = Object.values(logicJumpsPerCrossroadQuestion[questionItem.id] ?? []).sort(
      (first, second) => first.index - second.index,
    );
    const crossroadQuestionLogicJumpFormItems = mapLogicJumpRulesToFormItems(questionItem, crossroadQuestionLogicJumps);
    setLogicJumpFormItems(crossroadQuestionLogicJumpFormItems);
  }, [logicJumpsPerCrossroadQuestion, questionItem]);

  return { logicJumpFormItems, setLogicJumpFormItems };
};
