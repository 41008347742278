import { GenericQuestionConfig } from 'types/genericQuestion';
import { QuestionConfig, QuestionConfigType } from 'types/questionConfig';
import { resolveConfigValue } from 'utils/questionConfig';

export interface Config {
  configName: QuestionConfigType;
  configValue: string;
  id: number;
}

export const reduceConfigsToResolvedValues = (configs: Config[]): Config[] =>
  configs.reduce<Config[]>((configsForDisplay, config) => {
    const resolvedConfigValue = resolveConfigValue(config.configName, config.configValue);
    if (resolvedConfigValue) {
      return [
        ...configsForDisplay,
        {
          configName: config.configName,
          configValue: resolvedConfigValue,
          id: config.id,
        },
      ];
    }
    return configsForDisplay;
  }, []);

export const mapQuestionConfigToConfigType = (configs: QuestionConfig[] | GenericQuestionConfig[]) =>
  configs.map<Config>((config) => ({
    configName: config.config_name,
    configValue: config.config_value,
    id: config.id,
  }));
