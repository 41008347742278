import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZCDButton, ZCDInput } from '@zencity/common-ui';
import { OperatorSelect } from 'components/LogicJumpDialog/components/LogicJumpForm/components/OperatorSelect';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
  checkIsValidForm,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { Nullable, QuestionTypes } from '@zencity/survey-types';
import { QuestionChoiceSelect } from 'components/LogicJumpDialog/components/LogicJumpForm/components/QuestionChoiceSelect';
import { ScaleNumberInput } from 'components/LogicJumpDialog/components/LogicJumpForm/components/ScaleNumberInput';
import { DestinationQuestionEndScreenSelect } from 'components/LogicJumpDialog/components/LogicJumpForm/components/DestinationQuestionEndScreenSelect/DestinationQuestionEndScreenSelect';
import { OriginQuestionSelect } from 'components/LogicJumpDialog/components/LogicJumpForm/components/OriginQuestionSelect/OriginQuestionSelect';
import { useAppSelector } from 'customHooks/hooks';
import { RootState } from 'store';
import { Question } from 'types/questions';
import classnames from 'classnames';
import styles from './LogicJumpForm.module.scss';

export const renderConditionValueByQuestionType = (
  logicJumpFormItem: LogicJumpFormItem,
  handleOnChangeFormData: (updatedFormValues: LogicJumpFormItemChangeType) => void,
  selectedOriginQuestion?: Question,
): Nullable<ReactElement> => {
  if (!selectedOriginQuestion) {
    return null;
  }
  switch (selectedOriginQuestion.question_type as QuestionTypes) {
    case QuestionTypes.CHOICES:
    case QuestionTypes.DROPDOWN:
      return (
        <QuestionChoiceSelect
          logicJumpFormItem={logicJumpFormItem}
          onChange={handleOnChangeFormData}
          selectedOriginQuestion={selectedOriginQuestion}
        />
      );
    case QuestionTypes.SCALE:
      return (
        <ScaleNumberInput
          logicJumpFormItem={logicJumpFormItem}
          onChange={handleOnChangeFormData}
          selectedOriginQuestion={selectedOriginQuestion}
        />
      );
    default:
      return null;
  }
};

interface Props {
  logicJumpFormItem: LogicJumpFormItem;
  handleOnSetLogicJumpFormItem: (logicJumpFormItem: LogicJumpFormItem) => void;
  disableEditing?: boolean;
}

/**
 * The form for setting a logic jump.
 */
export const LogicJumpForm = (props: Props): ReactElement => {
  const { logicJumpFormItem: formItem, handleOnSetLogicJumpFormItem, disableEditing = false } = props;
  const { questions } = useAppSelector((state: RootState) => state.questions);

  const [logicJumpFormItem, setLogicJumpFormItem] = useState<LogicJumpFormItem>(formItem);
  const { t: translate } = useTranslation();

  const handleOnChangeFormData = (updatedFormValues: LogicJumpFormItemChangeType) => {
    setLogicJumpFormItem((prevFormItem) => ({
      ...prevFormItem,
      ...updatedFormValues,
    }));
  };

  const handleOnSaveEdit = () => {
    logicJumpFormItem.inEditMode = !logicJumpFormItem.inEditMode;
    handleOnSetLogicJumpFormItem(logicJumpFormItem);
  };

  const handleOnDelete = () => {
    logicJumpFormItem.deleted = true;
    logicJumpFormItem.inEditMode = false;
    handleOnSetLogicJumpFormItem(logicJumpFormItem);
  };

  const saveEditText = logicJumpFormItem.inEditMode ? translate('common.done') : translate('common.edit');
  const selectedOriginQuestion = questions.find(
    (question) => question.id === (logicJumpFormItem.selectedOriginQuestionId ?? -1),
  );

  return (
    <div className={classnames(styles.logicJumpForm, { [styles.noActions]: disableEditing })}>
      <b className={styles.originCondition}>{translate('common.if')}</b>
      <div className={styles.questionText}>
        <OriginQuestionSelect onChange={handleOnChangeFormData} logicJumpFormItem={logicJumpFormItem} />
      </div>
      <div className={styles.conditionOperator}>
        <OperatorSelect logicJumpFormItem={logicJumpFormItem} onChange={handleOnChangeFormData} />
      </div>
      <div className={styles.conditionValue}>
        {logicJumpFormItem.selectedOriginQuestionId &&
          renderConditionValueByQuestionType(logicJumpFormItem, handleOnChangeFormData, selectedOriginQuestion)}
      </div>
      <b className={styles.destinationText}>{translate('common.then')}</b>
      <div className={styles.actionOperator}>
        <ZCDInput disabled value={translate('logicJumpDialog.goTo').toString()} />
      </div>
      <div className={styles.destinationChoice}>
        <DestinationQuestionEndScreenSelect
          logicJumpFormItemValues={{
            selectedDestinationEndScreenId: logicJumpFormItem.selectedDestinationEndScreenId,
            selectedDestinationQuestionId: logicJumpFormItem.selectedDestinationQuestionId,
            crossroadQuestionItem: logicJumpFormItem.crossroadQuestionItem,
            inEditMode: logicJumpFormItem.inEditMode,
            id: logicJumpFormItem.id,
          }}
          onChange={handleOnChangeFormData}
        />
      </div>
      {!disableEditing && (
        <>
          <div className={styles.saveEditButton}>
            <ZCDButton onClick={handleOnSaveEdit} disabled={!checkIsValidForm(logicJumpFormItem)} text={saveEditText} />
          </div>
          <div className={styles.deleteButton}>
            <ZCDButton variant="secondary" text={translate('common.delete')} onClick={handleOnDelete} />
          </div>
        </>
      )}
    </div>
  );
};
