import { HorizontalSeparator } from '@zencity/common-ui';
import { CheckboxAsSwitch } from 'components/CheckboxAsSwitch/CheckboxAsSwitch';
import { QuestionEditActionsMenu } from 'components/QuestionEditActionsMenu/QuestionEditActionsMenu';
import { QuestionIndex } from 'components/QuestionIndex/QuestionIndex';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { DebounceDelay } from 'constants/misc';
import { useAppDispatch, useFirstMountState } from 'customHooks/hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateQuestion, writeQuestionConfig } from 'services/questions';
import { GenericQuestion, QuestionType } from 'types/genericQuestion';
import { Nullable } from 'types/misc';
import { QuestionConfigType } from 'types/questionConfig';
import { QuestionItem } from 'types/questions';
import { useDebouncedCallback } from 'use-debounce';
import {
  findQuestionConfig,
  getGenericQuestionConfig,
  getGenericQuestionConfigValue,
  getQuestionConfig,
  resolveRequiredConfig,
} from 'utils/genericAndQuestionConfigs';
import styles from './OverrideQuestion.module.scss';
import { ShowingOriginalValue } from './ShowingOriginalValue';

interface OverrideQuestionProps {
  questionItem: QuestionItem;
  genericQuestion: GenericQuestion;
  resolvedIndex: string;
  children?: React.ReactNode;
}

/**
 * The "edit mode" of a Question in the context of a Survey.
 *
 * To distinguish between the edit mode of a Generic Question, this component
 * is named as "override" as it overrides some values of a Generic Question in
 * a specific Survey.
 */
// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export const OverrideQuestion = (props: OverrideQuestionProps): ReactElement => {
  const { genericQuestion, questionItem, resolvedIndex, children } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  // The original values are the values of the Generic Question.
  const [showingOriginalValues, setShowingOriginalValues] = useState(true);
  const questionIsRequired = useMemo(
    () =>
      resolveRequiredConfig(
        findQuestionConfig(questionItem.item, genericQuestion, QuestionConfigType.REQUIRED)?.configValue,
      ),
    [genericQuestion, questionItem],
  );
  const [questionOverriddenText, setQuestionOverriddenText] = useState<Nullable<string>>(
    questionItem.item.overridden_text,
  );
  const questionConfigDescription = useMemo(
    () => getQuestionConfig(questionItem.item, QuestionConfigType.DESCRIPTION)?.config_value || null,
    [questionItem],
  );
  const [questionOverriddenDescription, setQuestionOverriddenDescription] =
    useState<Nullable<string>>(questionConfigDescription);

  const originalDescription = getGenericQuestionConfigValue(genericQuestion, QuestionConfigType.DESCRIPTION);
  const displayChildren = genericQuestion.question_type !== QuestionType.GROUP;

  const isFirstMount = useFirstMountState();

  const toggleShowingOriginal = (isChecked: boolean) => setShowingOriginalValues(isChecked);
  const toggleQuestionIsRequired = (isChecked: boolean) => {
    const originalRequiredConfig = getGenericQuestionConfig(genericQuestion, QuestionConfigType.REQUIRED);
    const value = isChecked ? '1' : '0';

    const configValuesAreEqual = originalRequiredConfig && originalRequiredConfig.config_value === value;
    const payload = {
      questionId: questionItem.id,
      config_name: QuestionConfigType.REQUIRED,
      // When setting the "required" value, check that the GenericConfig value is equal to the
      // QuestionConfig value, If both config values are equal, then the request will soft-delete
      // the QuestionConfig. Otherwise, set the overridden QuestionConfig value.
      config_value: configValuesAreEqual ? null : value,
    };
    dispatch(writeQuestionConfig(payload));
  };

  const debouncedUpdateQuestion = useDebouncedCallback(() => {
    const payload = {
      questionId: questionItem.id,
      // Use `null` when the overridden text is an empty string.
      overridden_text: questionOverriddenText || null,
    };

    dispatch(updateQuestion(payload));
  }, DebounceDelay.TEXT_INPUT);

  const onChangeOverriddenText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestionOverriddenText(event.target.value);
    debouncedUpdateQuestion();
  };

  useEffect(() => {
    if (isFirstMount) {
      return;
    }
    setQuestionOverriddenText(questionItem.item.overridden_text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionItem.item.overridden_text]);

  const debouncedUpdateQuestionDescription = useDebouncedCallback(() => {
    const payload = {
      questionId: questionItem.id,
      config_name: QuestionConfigType.DESCRIPTION,
      config_value: questionOverriddenDescription || null,
    };
    dispatch(writeQuestionConfig(payload));
  }, DebounceDelay.TEXT_INPUT);

  const onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestionOverriddenDescription(event.target.value);
    debouncedUpdateQuestionDescription();
  };

  useEffect(() => {
    if (isFirstMount) {
      return;
    }
    setQuestionOverriddenDescription(questionConfigDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionConfigDescription]);

  return (
    <div className={styles.overrideQuestionWrapper}>
      <div className={styles.questionViewEditGrid}>
        <QuestionIndex resolvedIndex={resolvedIndex} />
        <div className={styles.formWrapper}>
          <QuestionTextArea
            placeholder={genericQuestion.text}
            value={questionOverriddenText || ''}
            onChange={onChangeOverriddenText}
          />
          {showingOriginalValues && <ShowingOriginalValue value={genericQuestion.text} />}
          <QuestionTextArea
            className={styles.overrideDescription}
            placeholder={originalDescription || translate('overrideQuestion.addDescription')}
            value={questionOverriddenDescription || ''}
            onChange={onChangeDescription}
          />
          {showingOriginalValues && <ShowingOriginalValue value={originalDescription} />}
          <div className={styles.childrenWrapper}>{displayChildren && children}</div>
        </div>
      </div>

      <HorizontalSeparator />

      <div className={styles.actionsWrapper}>
        <CheckboxAsSwitch
          inputId={`show-original-question-${questionItem.id}`}
          labelText={translate('overrideQuestion.showOriginalContent')}
          isChecked={showingOriginalValues}
          onCheckHandler={toggleShowingOriginal}
        />
        <div className={styles.rightArea}>
          <div className={styles.aspect}>{genericQuestion?.aspect?.label}</div>
          <CheckboxAsSwitch
            inputId={`question-${questionItem.id}-is-required`}
            labelText={translate('common.required')}
            isChecked={questionIsRequired}
            onCheckHandler={toggleQuestionIsRequired}
          />
          <QuestionEditActionsMenu questionItem={questionItem} />
        </div>
      </div>
    </div>
  );
};
