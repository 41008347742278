import {
  convertQuestionItemsToQuestions,
  DraggableType,
  mapQuestionIdToIndexAndGroup,
  reduceQuestionsIntoGroups,
} from 'components/BuildForm/BuildForm.helpers';
import { EndScreen } from 'components/EndScreen/EndScreen';
import { GroupQuestion } from 'components/GroupQuestion/GroupQuestion';
import { determineQuestionTypeComponent } from 'components/QuestionWrapper/QuestionWrapper.helpers';
import { REACT_SORTABLE_OPTIONS } from 'constants/misc';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { updateIndexes } from 'services/questions';
import { updateQuestionsOrder } from 'slices/questions';
import { updateResolvedIndexes } from 'slices/surveyManage';
import { RootState } from 'store';
import { QuestionType } from 'types/genericQuestion';
import { QuestionItem } from 'types/questions';
import { logger } from 'utils/logger';
import { SurveyStatus } from '@zencity/survey-types';
import styles from './BuildForm.module.scss';

export enum BuildFormScreen {
  MANAGE = 'manage',
  BUILD = 'build',
}

interface Props {
  screen: BuildFormScreen;
}

/**
 * The main questions form on the Build page.
 */
export const BuildForm = (props: Props): ReactElement => {
  const { screen } = props;
  const { toastError } = useContext(SurveyManagerToastContext);
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector((state: RootState) => state.surveys);
  const { questions, currentSurveyId } = useAppSelector((state: RootState) => state.questions);
  const { endScreens } = useAppSelector((state: RootState) => state.endScreens);
  const [questionItems, setQuestionItems] = useState<QuestionItem[]>([]);

  const isBuildScreen = screen === BuildFormScreen.BUILD;
  // The `currentSurveyId` should be set in this component's parent component.
  const currentSurvey = surveys[currentSurveyId as number];
  const isClosed = currentSurvey?.status === SurveyStatus.CLOSED;
  const isLocked = currentSurvey?.is_locked;
  const allowEditMode = isBuildScreen && !isLocked && !isClosed;

  useEffect(() => {
    if (questions.length > 0) {
      const reducedQuestionItems = reduceQuestionsIntoGroups(questions);
      setQuestionItems(reducedQuestionItems);

      // Calculate the resolved indexes and update the slice.
      dispatch(updateResolvedIndexes(reducedQuestionItems));
    }
  }, [dispatch, questions]);

  /**
   * Handle updating question indexes and groups with debounce after a component
   * is dragged and dropped.
   */
  const handleOnEndDrop = () => {
    try {
      const questionIndexMap = mapQuestionIdToIndexAndGroup(questionItems);
      const reorderedQuestionsList = convertQuestionItemsToQuestions(questionIndexMap, questions);
      dispatch(updateQuestionsOrder(reorderedQuestionsList));
      dispatch(updateIndexes());
    } catch (error) {
      toastError();
      logger.error(error);
    }
  };

  return (
    <>
      <ReactSortable
        disabled={!allowEditMode}
        list={questionItems}
        setList={setQuestionItems}
        className={styles.buildForm}
        group={{
          name: DraggableType.GROUP_QUESTION,
          pull: [DraggableType.GROUP_QUESTION, DraggableType.NORMAL_QUESTION],
          put: [DraggableType.GROUP_QUESTION, DraggableType.NORMAL_QUESTION],
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...REACT_SORTABLE_OPTIONS}
        onEnd={handleOnEndDrop}
      >
        {questionItems.map((questionItem, index) =>
          questionItem.item.question_type === QuestionType.GROUP ? (
            <GroupQuestion
              key={`${QuestionType.GROUP}-question-${questionItem.id}`}
              questionItem={questionItem}
              index={index}
              handleOnEndDrop={handleOnEndDrop}
              allowEditMode={allowEditMode}
              setQuestionItems={setQuestionItems}
            />
          ) : (
            determineQuestionTypeComponent(questionItem, index, allowEditMode)
          ),
        )}
      </ReactSortable>
      <div className={styles.endScreensBuilder}>
        {endScreens.map((endScreen) => (
          <EndScreen endScreen={endScreen} allowEditMode={allowEditMode} key={endScreen.id} />
        ))}
      </div>
    </>
  );
};
