import { zcdNotify } from '@zencity/common-ui';
import { ActionItem } from 'components/ManageDropdownButton/ActionItem';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { republishSurvey } from 'services/survey';
import { logger } from 'utils/logger';

interface Props {
  surveyId: number;
  closeDropdown: () => void;
}

export const RepublishSurveyActionItem = (props: Props): ReactElement => {
  const { surveyId, closeDropdown } = props;
  const { t: translate } = useTranslation();

  const handleOnRepublishClick = async () => {
    // The Survey is republished for all languages by invalidating the RFA's cache.
    try {
      await republishSurvey(surveyId);
      zcdNotify.success(translate('surveyManage.republishSurvey.successMessage'));
    } catch (error) {
      zcdNotify.error(translate('surveyManage.republishSurvey.errorMessage'));
      logger.error(error);
    } finally {
      closeDropdown();
    }
  };

  return (
    <ActionItem
      iconName="refresh"
      text={translate('surveyManage.republishSurvey.republish')}
      onClick={handleOnRepublishClick}
    />
  );
};
