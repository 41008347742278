/* eslint-disable max-lines */
import React from 'react';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { TFunction } from 'react-i18next';
import { Survey, SurveyType } from 'types/survey';
import { ClientsById } from 'types/client';
import { cloneSurveyInstance, createSurvey, updateSurvey } from 'services/survey';
import { addOrUpdateSurvey, SurveysState } from 'slices/survey';
import { RouterPath, SurveyChildPath } from 'routerPaths';
import { logger } from 'utils/logger';
import { fetchSurveyGroupsByClientAndSurveyType } from 'services/surveyGroup';
import { isNumeric } from 'utils/misc';
import { Cadence } from '@zencity/survey-types';
import { zcdNotify } from '@zencity/common-ui';

export interface CurrentSurvey {
  id?: number;
  clientId?: number;
  surveyType?: SurveyType;
  surveyCadence?: Cadence;
  titleForDisplay?: string;
  internalTitlePattern?: string;
  internalTitleSuffix?: string;
}

const INTERNAL_TITLE_SUFFIX_DELIMITER = ' | ';

/**
 * Parse a numeric URL parameter.
 */
export const parseNumericUrlParam = (urlParam: string): number => {
  if (urlParam.length && !isNumeric(urlParam)) {
    throw new Error(`ID from param is not a number: ${urlParam}`);
  }
  return parseInt(urlParam);
};

/**
 * Build a survey's internal title.
 */
export const buildInternalTitle = (currentSurvey: CurrentSurvey): string =>
  `${currentSurvey.internalTitlePattern}${INTERNAL_TITLE_SUFFIX_DELIMITER}${currentSurvey.internalTitleSuffix}`;

/**
 * Generate a survey path, given a survey ID. Useful for navigation to a survey page.
 */
export const generateSurveyPath = (childSurveyPath: SurveyChildPath, surveyId: number): string =>
  generatePath(`${RouterPath.SURVEY}/${childSurveyPath}`, {
    surveyId: surveyId.toString(),
  });

/**
 * Indicated whether the form using this helper should be in edit mode.
 */
export const isFormInEditMode = (currentSurvey: CurrentSurvey, inCloneMode: boolean): boolean =>
  !!currentSurvey.id && !inCloneMode;

/**
 * Indicate whether the given survey is valid for submitting.
 */
export const isValidForSubmit = (currentSurvey: CurrentSurvey): boolean =>
  !!currentSurvey?.clientId &&
  !!currentSurvey?.surveyType &&
  !!currentSurvey?.titleForDisplay &&
  !!currentSurvey?.internalTitleSuffix;

/**
 * Generate the prefix for the internal title of a Survey.
 *
 * This convention a specific pattern applied to all Surveys.
 */
export const generateInternalTitlePrefix = (
  clientId: number,
  surveyType: string,
  clientsById: ClientsById,
  translate: TFunction<'translation', undefined>,
): string => {
  if (!clientsById[clientId]) {
    return '';
  }

  const clientName = clientsById[clientId].name;
  const surveyTypeLabel = translate(`surveyType.${surveyType}`);

  return `${clientName}, ${surveyTypeLabel}`;
};

/**
 * Update the current survey.
 */
export const updateCurrentSurvey = (
  survey: Survey,
  clientsById: ClientsById,
  translate: TFunction<'translation', undefined>,
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>,
): void => {
  // Extract the suffix from the Survey's internal title, empty string fallback.
  const internalTitleSuffix = survey.internal_title.split(INTERNAL_TITLE_SUFFIX_DELIMITER)[1] || '';

  setCurrentSurvey({
    id: survey.id,
    clientId: survey.survey_group.client.id,
    surveyType: survey.survey_group.type as SurveyType,
    surveyCadence: survey.survey_group.cadence as Cadence,
    titleForDisplay: survey.survey_group.title_for_display,
    internalTitlePattern: generateInternalTitlePrefix(
      survey.survey_group.client.id,
      survey.survey_group.type,
      clientsById,
      translate,
    ),
    internalTitleSuffix,
  });
};

interface CreateOrEditSurveyProps {
  allowedToSelectCadence: boolean;
  currentSurvey: CurrentSurvey;
  surveyRequestId?: number;
  existingSurvey?: Survey;
}

export const createOrEditSurvey = async (props: CreateOrEditSurveyProps): Promise<Survey | undefined> => {
  const { allowedToSelectCadence, currentSurvey, existingSurvey, surveyRequestId } = props;

  if (!currentSurvey?.clientId || !currentSurvey?.surveyType || !currentSurvey?.titleForDisplay) {
    return undefined;
  }

  const cadencePayload =
    allowedToSelectCadence && currentSurvey?.surveyCadence
      ? { selectedSurveyCadence: currentSurvey.surveyCadence }
      : {};

  const internalTitle = buildInternalTitle(currentSurvey);
  const payload = {
    ...cadencePayload,
    selectedClientId: currentSurvey.clientId,
    selectedSurveyType: currentSurvey.surveyType,
    surveyTitleForDisplay: currentSurvey.titleForDisplay,
    surveyInternalTitle: internalTitle,
    ...(surveyRequestId && { surveyRequestId }),
  };

  if (existingSurvey) {
    const { id: surveyId } = existingSurvey;
    const updatedSurvey = await updateSurvey({
      internalTitle,
      surveyId,
    });
    return updatedSurvey;
  }
  const newSurvey = await createSurvey(payload);
  return newSurvey;
};

interface CloneSurveyHandlerProps {
  surveyId: number;
  currentSurvey: CurrentSurvey;
  dispatch: ThunkDispatch<{ surveys: SurveysState }, undefined, AnyAction> & Dispatch<AnyAction>;
  navigate: NavigateFunction;
  setCloneSurveyInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * This occurs when a user clicks next when cloning a survey.
 * This will clone a survey and its questions, and move to the "Build"
 * screen, where the store will be populated accordingly.
 */
export const handleCloneSurvey = (props: CloneSurveyHandlerProps): void => {
  const { surveyId, currentSurvey, dispatch, navigate, setCloneSurveyInProgress } = props;
  setCloneSurveyInProgress(true);

  const internalTitle = buildInternalTitle(currentSurvey);

  cloneSurveyInstance({
    // Because all of these fields are required for the `Next` button
    // to be enabled, we can assume that currentSurvey contains all of the values.
    // currentSurvey cannot be sent due to a dependency cycle.
    surveyId,
    internalTitle,
    titleForDisplay: currentSurvey.titleForDisplay as string,
    surveyCadence: currentSurvey.surveyCadence as Cadence,
    clientId: currentSurvey.clientId as number,
    surveyType: currentSurvey.surveyType as SurveyType,
  })
    .then((clonedSurvey) => {
      dispatch(addOrUpdateSurvey(clonedSurvey));

      const surveyBuildUrl = generateSurveyPath(SurveyChildPath.BUILD, clonedSurvey.id);
      navigate(surveyBuildUrl);
    })
    .catch((error) => {
      const asError = error as Error;
      zcdNotify.error(asError.message);
      logger.error(`Clone survey error: ${error}`);
    })
    .finally(() => {
      setCloneSurveyInProgress(false);
    });
};

interface PrepopulateRecurringSurveyParams {
  clientId: number;
  surveyType: SurveyType;
  setCurrentSurvey: React.Dispatch<React.SetStateAction<CurrentSurvey>>;
  clientsById: ClientsById;
  translate: TFunction<'translation', undefined>;
  recurringSurveyTypes: SurveyType[];
  setPrepopulateRecurringSurveyInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Prepopulate the current survey fields based off of the client and survey type.
 * If the survey type is of a recurring survey, then the relevant fields will also be prepopulated.
 */
export const prepopulateSurveyByClientAndType = (params: PrepopulateRecurringSurveyParams): void => {
  const {
    clientId,
    surveyType,
    clientsById,
    translate,
    setCurrentSurvey,
    setPrepopulateRecurringSurveyInProgress,
    recurringSurveyTypes,
  } = params;

  const internalTitlePattern = generateInternalTitlePrefix(clientId, surveyType, clientsById, translate);

  if (recurringSurveyTypes.includes(surveyType)) {
    setPrepopulateRecurringSurveyInProgress(true);

    fetchSurveyGroupsByClientAndSurveyType(clientId, surveyType)
      .then((surveyGroupResults) => {
        if (surveyGroupResults.count) {
          // It is assumed that the results will contain 1 survey group
          // per client-survey type combination.
          const clientSurveyGroup = surveyGroupResults.results[0];

          setCurrentSurvey((prevCurrentSurvey) => ({
            ...prevCurrentSurvey,
            titleForDisplay: clientSurveyGroup.title_for_display,
            surveyCadence: clientSurveyGroup.cadence as Cadence,
            disableEditingCadence: true,
            internalTitlePattern,
          }));
        } else {
          // When switching a client/survey type, if there is no survey group, clear the form
          // title for display and cadence.
          setCurrentSurvey((prevCurrentSurvey) => ({
            ...prevCurrentSurvey,
            titleForDisplay: undefined,
            surveyCadence: undefined,
            disableEditingCadence: false,
            internalTitlePattern,
          }));
        }
      })
      .catch((error) => {
        logger.error(`Error fetching survey group for client ID #${clientId} and survey type ${surveyType}: ${error}`);
      })
      .finally(() => {
        setPrepopulateRecurringSurveyInProgress(false);
      });
  } else {
    setCurrentSurvey((prevCurrentSurvey) => ({
      ...prevCurrentSurvey,
      disableEditingCadence: true,
      internalTitlePattern,
    }));
  }
};
