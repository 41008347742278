import { HorizontalSeparator, ZCDTag } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { checkAllLogicJumpFormItemsAreValid } from 'components/LogicJumpDialog/LogicJumpDialog.helpers';
import { AddRuleButton } from 'components/LogicJumpDialog/components/AddRuleButton';
import { FallbackRule } from 'components/LogicJumpDialog/components/FallbackRule/FallbackRule';
import { LogicJumpForm } from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm';
import { LogicJumpFormItem } from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionItem } from 'types/questions';
import { v4 } from 'uuid';
import { updateChangedLogicJumpFormItem } from 'components/LogicJumpDialog/components/LogicJumpFormList/LogicJumpFormList.helpers';
import { useAppSelector } from 'customHooks/hooks';
import { RootState } from 'store';
import styles from './LogicJumpFormList.module.scss';

const FALLBACK_RULE_FORM_ITEM_ID = 'fallback-rule';

interface Props {
  questionItem: QuestionItem;
  logicJumpFormItems: LogicJumpFormItem[];
  setLogicJumpFormItems: React.Dispatch<React.SetStateAction<LogicJumpFormItem[]>>;
}

export const LogicJumpFormList = (props: Props): ReactElement => {
  const { questionItem, logicJumpFormItems, setLogicJumpFormItems } = props;
  const { t: translate } = useTranslation();
  const fallbackRuleFormItem = logicJumpFormItems.find((logicJumpFormItem) => logicJumpFormItem.always);
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);

  const handleOnSetLogicJumpFormItem = (logicJumpFormItem: LogicJumpFormItem) => {
    setLogicJumpFormItems((prevFormItems) => updateChangedLogicJumpFormItem(prevFormItems, logicJumpFormItem));
  };

  const handleOnAddRule = () => {
    setLogicJumpFormItems((rest) => [
      ...rest,
      {
        id: v4(),
        crossroadQuestionItem: questionItem,
        index: rest.length,
        inEditMode: true,
        deleted: false,
      },
    ]);
  };

  const handleOnSetFallbackRule = (
    selectedDestinationEndScreenId?: number,
    selectedDestinationQuestionId?: number,
    deleted?: boolean,
  ) => {
    if (fallbackRuleFormItem) {
      handleOnSetLogicJumpFormItem({
        ...fallbackRuleFormItem,
        selectedDestinationEndScreenId,
        selectedDestinationQuestionId,
        always: true,
        deleted: deleted ?? false,
      });
    } else {
      const newFallbackRuleFormItem: LogicJumpFormItem = {
        id: v4(),
        crossroadQuestionItem: questionItem,
        index: logicJumpFormItems.length,
        inEditMode: false,
        deleted: false,
        selectedDestinationEndScreenId,
        selectedDestinationQuestionId,
        always: true,
      };
      setLogicJumpFormItems((prevFormItems) => [...prevFormItems, newFallbackRuleFormItem]);
    }
  };

  const activeLogicJumpFormItems = logicJumpFormItems.filter(
    (logicJumpFormItem) => !logicJumpFormItem.deleted && !logicJumpFormItem.always,
  );
  const deletedLogicJumpFormItems = logicJumpFormItems.filter(
    (logicJumpFormItem) => logicJumpFormItem.deleted && !logicJumpFormItem.always,
  );
  const isAddButtonDisabled = !checkAllLogicJumpFormItemsAreValid(logicJumpFormItems);

  return (
    <Flex flexDirection="column" gap="8px">
      {activeLogicJumpFormItems.map((logicJumpFormItem, index) => (
        <div className={styles.logicJumpFormContainer} key={logicJumpFormItem.id}>
          <LogicJumpForm
            logicJumpFormItem={logicJumpFormItem}
            handleOnSetLogicJumpFormItem={handleOnSetLogicJumpFormItem}
          />
          {index < activeLogicJumpFormItems.length - 1 && <HorizontalSeparator />}
        </div>
      ))}
      <AddRuleButton onClick={handleOnAddRule} disabled={isAddButtonDisabled} />
      <HorizontalSeparator />
      <FallbackRule
        handleOnSetFallbackRule={handleOnSetFallbackRule}
        logicJumpFormItemValues={{
          selectedDestinationEndScreenId: fallbackRuleFormItem?.selectedDestinationEndScreenId,
          selectedDestinationQuestionId: fallbackRuleFormItem?.selectedDestinationQuestionId,
          id: fallbackRuleFormItem?.id ?? FALLBACK_RULE_FORM_ITEM_ID,
        }}
        crossroadQuestionItem={questionItem}
      />
      {deletedLogicJumpFormItems.map(({ id, selectedOriginQuestionId }, index) => (
        <div key={id}>
          <ZCDTag
            fontColor="white"
            preIcon="warning-filled"
            text={translate('logicJumpDialog.requiredConfigWarning', {
              originQuestionIndex: resolvedIndexes[selectedOriginQuestionId ?? -1],
            })}
          />
          {index < deletedLogicJumpFormItems.length - 1 && <Spacer orientation="vertical" size="xsmall" />}
        </div>
      ))}
    </Flex>
  );
};
