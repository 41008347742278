/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyLanguage } from '@zencity/survey-types';
import { TranslationSection } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { fetchSurveyLanguagesBySurveyId } from 'services/surveyLanguage';
import { initialState as globalConfigInitialState } from 'slices/globalConfig';
import { RequestStatus } from 'types/misc';
import { QuestionItem, ResolvedIndexes } from 'types/questions';
import { Survey } from 'types/survey';
import { resolvedQuestionsIndexes } from 'utils/question';

export enum SurveyManageDisplay {
  TRANSLATION = 'translation',
  PREVIEW = 'preview',
}

export interface SurveyManageState {
  selectedLanguage: string;
  surveyLanguagesById: { [surveyLanguageId: number]: SurveyLanguage };
  selectedSurveyLanguage?: SurveyLanguage;
  currentSurvey?: Survey;
  fetchStatus: string;
  currentDisplay: SurveyManageDisplay;
  errorMessage?: string;
  resolvedIndexes: ResolvedIndexes;
  translationSections: TranslationSection[];
}

const initialState: SurveyManageState = {
  surveyLanguagesById: {},
  selectedLanguage: globalConfigInitialState.defaultLanguage,
  fetchStatus: RequestStatus.IDLE,
  currentDisplay: SurveyManageDisplay.PREVIEW,
  resolvedIndexes: {},
  translationSections: [],
};

const slice = createSlice({
  name: 'surveyManage',
  initialState,
  reducers: {
    setCurrentSurvey: (state: SurveyManageState, action: PayloadAction<Survey>) => {
      const survey = action.payload;
      state.currentSurvey = survey;
    },
    setSurveyLanguage: (state: SurveyManageState, action: PayloadAction<SurveyLanguage>) => {
      const surveyLanguage = action.payload;
      state.surveyLanguagesById[surveyLanguage.id] = surveyLanguage;
    },
    setSelectedSurveyLanguage: (state: SurveyManageState, action: PayloadAction<SurveyLanguage | undefined>) => {
      const surveyLanguage = action.payload;
      if (!surveyLanguage) {
        state.selectedSurveyLanguage = undefined;
        return;
      }
      state.surveyLanguagesById[surveyLanguage.id] = surveyLanguage;
      state.selectedSurveyLanguage = surveyLanguage;
    },
    setCurrentLanguage: (state: SurveyManageState, action: PayloadAction<string>) => {
      const language = action.payload;
      state.selectedLanguage = language;
    },
    setCurrentDisplay: (state: SurveyManageState, action: PayloadAction<SurveyManageDisplay>) => {
      const surveyManageScreen = action.payload;
      state.currentDisplay = surveyManageScreen;
    },
    updateResolvedIndexes: (state: SurveyManageState, action: PayloadAction<QuestionItem[]>) => {
      state.resolvedIndexes = resolvedQuestionsIndexes(action.payload);
    },
    setTranslationSections: (state: SurveyManageState, action: PayloadAction<TranslationSection[]>) => {
      const translationSections = action.payload;
      state.translationSections = translationSections;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchSurveyLanguagesBySurveyId.fulfilled,
        (state: SurveyManageState, action: PayloadAction<SurveyLanguage[]>) => {
          state.fetchStatus = RequestStatus.SUCCESS;
          const surveyLanguages = action.payload;
          state.surveyLanguagesById = {};
          surveyLanguages.forEach((surveyLanguage) => {
            state.surveyLanguagesById[surveyLanguage.id] = surveyLanguage;
          });
        },
      )
      .addCase(fetchSurveyLanguagesBySurveyId.pending, (state: SurveyManageState) => {
        state.fetchStatus = RequestStatus.LOADING;
      })
      .addCase(fetchSurveyLanguagesBySurveyId.rejected, (state: SurveyManageState, action) => {
        state.fetchStatus = RequestStatus.ERROR;
        state.errorMessage = action.error.message;
      });
  },
});

export const {
  setCurrentSurvey,
  setSelectedSurveyLanguage,
  setCurrentLanguage,
  setCurrentDisplay,
  updateResolvedIndexes,
  setTranslationSections,
  setSurveyLanguage,
} = slice.actions;
export default slice;
