import { RequestStatus } from 'types/misc';

export const statusIsIdle = (status: string): boolean => status === RequestStatus.IDLE;
export const statusIsLoading = (status: string): boolean => status === RequestStatus.LOADING;
export const statusIsSuccessful = (status: string): boolean => status === RequestStatus.SUCCESS;
export const statusIsError = (status: string): boolean => status === RequestStatus.ERROR;

export const isNumeric = (value: string): boolean => !Number.isNaN(parseInt(value));

/**
 * Converts a number to an english letter. The number should be zero-based (starting from 0).
 * Example: (value = 0, uppercase = false) --> Returns 'a'.
 *
 * @param value - The number value.
 * @param upperCase - Boolean indicating whether to return an uppercase letter.
 */
export const convertNumberToLetter = (value: number, upperCase: boolean): string =>
  String.fromCharCode(upperCase ? 65 + value : 97 + value);

/**
 * Shuffle the given array.
 */
export const shuffleArray = <T>(nonShuffled: Array<T>): Array<T> =>
  nonShuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((nodeA, nodeB) => nodeA.sort - nodeB.sort)
    .map(({ value }) => value);

export const isStringType = (variable: unknown): boolean => typeof variable === 'string';

/**
 * Returns a new array with the given array chunked into smaller arrays of the given size.
 * Example: chunkArray([1, 2, 3, 4, 5, 6, 7, 8, 9], 2) --> [[1, 2], [3, 4], [5, 6], [7, 8], [9]]
 */
export const chunkArray = <T>(initialArray: Array<T>, chunkSize: number): T[][] => {
  if (chunkSize <= 0) {
    return [initialArray];
  }
  const chunkedArray = [];
  for (let index = 0; index < initialArray.length; index += chunkSize) {
    chunkedArray.push(initialArray.slice(index, index + chunkSize));
  }
  return chunkedArray;
};

export const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const isValidUrl = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const fallbackWordsToExclude = ['for', 'to', 'and', 'of', 'a', 'an', 'the', 'in'];
interface CapitalizeStringInterface {
  string: string;
  wordsToExclude?: string[];
}

export const capitalizeString = ({
  string,
  wordsToExclude = fallbackWordsToExclude,
}: CapitalizeStringInterface): string =>
  string
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      if (wordsToExclude.includes(word)) return word;
      return word.charAt(0).toUpperCase() + word.substr(1);
    })
    .join(' ');
