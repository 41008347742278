import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';

interface Props {
  name: string;
  linkUrl: string;
  color?: string;
}

export const SharingLink = ({ name, linkUrl, color }: Props): ReactElement => (
  <>
    <div
      style={{
        color,
      }}
    >
      {name}
    </div>
    <a href={linkUrl} target="_blank" rel="noreferrer">
      {linkUrl}
    </a>
    <Spacer orientation="vertical" size="small" />
  </>
);
