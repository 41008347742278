import { Nullable } from '@zencity/survey-types';
import i18next from 'i18next';
import { DateFormat, formatISODate } from 'utils/dates';

interface StatusTextParams {
  isPublishable: boolean;
  missingTranslationsCount: number;
  closedDate: Nullable<string>;
  publishedDate?: string;
}

export const getSurveyLanguageStatusText = (params: StatusTextParams) => {
  const { missingTranslationsCount, isPublishable, closedDate, publishedDate } = params;
  if (missingTranslationsCount > 0) {
    return `${i18next.t('surveyManage.surveyLanguage.missingTranslations', { missingTranslationsCount })}`;
  }
  if (closedDate) {
    return `${i18next.t('common.closed')}: ${formatISODate(closedDate, DateFormat.COMMAS_WITH_MONTH)}`;
  }
  if (isPublishable) {
    return `${i18next.t('surveyManage.surveyLanguage.available')}`;
  }
  if (publishedDate) {
    return `${i18next.t('common.published')}: ${formatISODate(publishedDate, DateFormat.COMMAS_WITH_MONTH)}`;
  }
  return '';
};
