import { ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import { ZCDTooltip, TooltipPlacement } from 'components/ZCDTooltip/ZCDTooltip';
import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './QuestionFlags.module.scss';

interface InformationFlagProps {
  icon: ReactElement;
  text: string;
}

const InformationFlag = (props: InformationFlagProps): ReactElement => {
  const { icon, text } = props;
  return (
    <div className={styles.informationFlag}>
      {icon}
      <p>{text}</p>
    </div>
  );
};

interface Props {
  editedFields?: boolean;
  specificCountries?: boolean;
  logicJumps?: boolean;
}

/**
 * Resolve which flag icon to display on the question.
 *
 * TODO: Add the icons for specific countries.
 */
// eslint-disable-next-line react/no-multi-comp
const ResolvedQuestionFlag = (props: Props): ReactElement => {
  if (Object.values(props).filter(Boolean).length > 1) {
    return <ZCDIcon name="warning-filled" color={ZCDIconColor.YELLOW} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editedFields, specificCountries, logicJumps } = props;
  if (editedFields) {
    return <ZCDIcon name="edit-underline" color={ZCDIconColor.YELLOW} />;
  }
  if (logicJumps) {
    return <ZCDIcon name="jump-in-logic" color={ZCDIconColor.YELLOW} />;
  }

  return <></>;
};

/**
 * Displays flags that tells the user specific information about the question.
 *
 * TODO: Add design for specific countries.
 */
// eslint-disable-next-line react/no-multi-comp
export const QuestionFlags = (props: Props): ReactElement => {
  // TODO: Add logic for remaining props.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editedFields = false, specificCountries = false, logicJumps = false } = props;

  const [displayTooltip, setDisplayTooltip] = useState(false);
  const { t: translate } = useTranslation();
  const flagIconRef = useRef<HTMLDivElement | null>(null);

  const handleOnMouseEnter = () => setDisplayTooltip(true);
  const handleOnMouseLeave = () => setDisplayTooltip(false);

  return (
    <>
      <div
        ref={flagIconRef}
        className={styles.flagsIcon}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ResolvedQuestionFlag {...props} />
      </div>
      <ZCDTooltip elementRef={flagIconRef} tooltipPlacement={TooltipPlacement.TOP} displayTooltip={displayTooltip}>
        {editedFields && (
          <InformationFlag
            icon={<ZCDIcon name="edit-underline" color={ZCDIconColor.WHITE} />}
            text={translate('questionFlags.editedFields')}
          />
        )}
        {logicJumps && (
          <InformationFlag
            icon={<ZCDIcon name="jump-in-logic" color={ZCDIconColor.WHITE} />}
            text={translate('questionFlags.logicJumps')}
          />
        )}
      </ZCDTooltip>
    </>
  );
};
