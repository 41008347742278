import { ZCDNotificationContainer } from '@zencity/common-ui';
import { DeleteGenericQuestion } from 'components/GenericQuestionsListTable/components/Actions/DeleteGenericQuestion';
import { EditGenericQuestion } from 'components/GenericQuestionsListTable/components/Actions/EditGenericQuestion';
import { CopyTextToClipboardButton } from 'components/CopyTextToClipboardButton/CopyTextToClipboardButton';
import React from 'react';
import { GenericQuestion } from 'types/genericQuestion';
import styles from './GenericQuestionActions.module.scss';

interface Props {
  genericQuestion: GenericQuestion;
  switchToNewQuestionTab?: () => void;
}

export const GenericQuestionActions = ({ genericQuestion, switchToNewQuestionTab }: Props): JSX.Element => (
  <div className={styles.wrapper}>
    <CopyTextToClipboardButton text={genericQuestion.text} />
    <EditGenericQuestion genericQuestion={genericQuestion} switchToNewQuestionTab={switchToNewQuestionTab} />
    <DeleteGenericQuestion genericQuestion={genericQuestion} />
    <ZCDNotificationContainer position="top-center" />
  </div>
);
