import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { Operator, QuestionTypes } from '@zencity/survey-types';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { RootState } from 'store';

const OPERATORS_PER_QUESTION_TYPE: Partial<Record<QuestionTypes, Operator[]>> = {
  [QuestionTypes.CHOICES]: [Operator.IS, Operator.IS_NOT],
  [QuestionTypes.DROPDOWN]: [Operator.IS, Operator.IS_NOT],
  [QuestionTypes.YES_NO]: [Operator.IS, Operator.IS_NOT],
  [QuestionTypes.SCALE]: [Operator.EQ, Operator.NEQ, Operator.LT, Operator.LTE, Operator.GT, Operator.GTE],
};

const generateSelectOptions = (questionType?: QuestionTypes): SelectOption[] => {
  if (!questionType) {
    return [];
  }
  return Object.entries(Operator)
    .filter(([, operatorValue]) => OPERATORS_PER_QUESTION_TYPE[questionType]?.includes(operatorValue))
    .map<SelectOption>(([operatorKey, operatorValue]) => ({
      value: operatorValue,
      label: operatorKey,
    }));
};

const initializeSelectedOption = (selectOptions: SelectOption[], operator?: Operator): SelectOption | undefined => {
  if (!operator || !selectOptions.length) {
    return undefined;
  }
  return selectOptions.find((option) => option.value === operator);
};

interface Props {
  logicJumpFormItem: LogicJumpFormItem;
  onChange: (updatedFormValues: LogicJumpFormItemChangeType) => void;
}

export const OperatorSelect = (props: Props): ReactElement => {
  const { onChange, logicJumpFormItem } = props;
  const { questions } = useAppSelector((state: RootState) => state.questions);

  const { selectedOriginQuestionId, operator, inEditMode } = logicJumpFormItem;
  const selectedOriginQuestion = questions.find((question) => question.id === selectedOriginQuestionId);

  // Note: The casting for the `QuestionTypes` is because there
  // are many places in the code that rely on the `question_type` to be a string.
  // This should be fixed in a tech debt task: https://zencity.atlassian.net/browse/SRV-1335.
  const selectOptions = generateSelectOptions(selectedOriginQuestion?.question_type as QuestionTypes | undefined);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    initializeSelectedOption(selectOptions, operator),
  );

  const handleOnSelect = (newValue: SelectOption) => {
    setSelectedOption(newValue);
    onChange({
      operator: newValue.value as Operator,
    });
  };

  return (
    <ZCDSelect
      isDisabled={!selectedOriginQuestion || !inEditMode}
      options={selectOptions}
      value={selectedOption}
      onChange={(newValue) => handleOnSelect(newValue as SelectOption)}
    />
  );
};
