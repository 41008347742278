import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { Nullable } from '@zencity/survey-types';
import i18next from 'i18next';
import { EndScreenType } from 'types/endScreen';

interface DialogState {
  title: string;
  description: string;
  endScreenType: SelectOption;
  urlToRedirect: Nullable<string>;
  delayRedirect: number;
}

export enum DialogActionsType {
  UPDATE_TITLE = 'updateTitle',
  UPDATE_DESC = 'updateDesc',
  UPDATE_SCREEN_TYPE = 'updateScreenType',
  UPDATE_URL_TO_REDIRECT = 'updateUrlToRedirect',
  UPDATE_DELAY_REDIRECT = 'updateDelayRedirect',
  CLOSE = 'close',
}

type DialogActions =
  | { type: DialogActionsType.UPDATE_TITLE; payload: { title: string } }
  | { type: DialogActionsType.UPDATE_DESC; payload: { description: string } }
  | { type: DialogActionsType.UPDATE_SCREEN_TYPE; payload: { endScreenType: SelectOption } }
  | { type: DialogActionsType.CLOSE }
  | { type: DialogActionsType.UPDATE_URL_TO_REDIRECT; payload: { urlToRedirect: string } }
  | { type: DialogActionsType.UPDATE_DELAY_REDIRECT; payload: { delayRedirect: number } };

export const initialAddEndScreenState: DialogState = {
  title: i18next.t('endScreen.addTitle'),
  description: i18next.t('endScreen.addDescription'),
  endScreenType: { value: EndScreenType.COMPLETION, label: i18next.t(`screenType.completion`) },
  urlToRedirect: null,
  delayRedirect: 5,
};

export const reducer = (state: DialogState, action: DialogActions) => {
  switch (action.type) {
    case DialogActionsType.UPDATE_TITLE: {
      const { title } = action.payload;
      return { ...state, title };
    }
    case DialogActionsType.UPDATE_DESC: {
      const { description } = action.payload;
      return { ...state, description };
    }
    case DialogActionsType.UPDATE_SCREEN_TYPE: {
      const { endScreenType } = action.payload;
      return { ...state, endScreenType };
    }
    case DialogActionsType.UPDATE_DELAY_REDIRECT: {
      const { delayRedirect } = action.payload;
      return { ...state, delayRedirect };
    }
    case DialogActionsType.UPDATE_URL_TO_REDIRECT: {
      const { urlToRedirect } = action.payload;
      return { ...state, urlToRedirect };
    }
    case DialogActionsType.CLOSE:
      return { ...initialAddEndScreenState };
    default:
      throw new Error();
  }
};

export const SCREEN_TYPE_OPTIONS = Object.values(EndScreenType).map((type) => ({
  value: type,
  key: `screen-type-${type}`,
  label: i18next.t(`screenType.${type}`),
}));
