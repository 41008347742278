import { LogicJumpRuleBackoffice } from 'types/logicJump';

export const filterLogicJumpRulesForQuestion = (
  questionId: number,
  logicJumps: LogicJumpRuleBackoffice[],
): LogicJumpRuleBackoffice[] =>
  logicJumps.filter((logicJump) => {
    const { destination_question: destinationQuestion, expression } = logicJump;
    const isDestinationQuestion = destinationQuestion === questionId;
    // Note: Currently, only expressions as the `LogicJumpExpressionWithConditions` type is supported.
    if ('conditions' in expression) {
      const isOriginQuestion = expression.conditions.some(
        (condition) => condition.operand_a_origin.origin_question_id === questionId,
      );
      return isDestinationQuestion || isOriginQuestion;
    }
    return isDestinationQuestion;
  });
