/* eslint-disable max-depth */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
/* eslint-disable max-nested-callbacks */
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { getOrderedNumericValueThresholds } from '@zencity/surveys-common-utilities';
import { getCyclesStartingFromSelectedCycle } from 'screens/WidgetGenerator/utils/cycles';
import { fetchCrossTabQuestions } from 'services/crossTabs';
import { LeanClient } from 'types/client';
import { Nullable } from 'types/misc';
import { logger } from 'utils/logger';
import { isNumeric } from 'utils/misc';
import {
  CrossChoiceData,
  CrossChoicesScore,
  CrossScore,
  CrossTabsDataTypes,
  CrossTabsQuestionsData,
  CrossTabsWidgetData,
  DemographicAndGeographicBreakdown,
  DemographicGeographicCross,
  DemographicGeographicData,
  DemographicGeographicScore,
  DemographicGeographicType,
  QuestionChoicesCross,
  SelectedDateRange,
  SentimentBarGroupsWidgetData,
  SurveyCycle,
} from '../../utils/misc';
import { getQuestionScoreData } from '../questionsScore';

export const SENTIMENT_BOUNDS_THRESHOLD = 0.36;

const enum SortingLogicType {
  HARDCODED = 'hardcoded',
  FUNCTION = 'function',
}

const DEMOGRAPHICS_SORTING_ORDER: {
  [DemographicGeographicType.AGE]: { type: SortingLogicType.HARDCODED; values: string[] };
  [DemographicGeographicType.INCOME]: {
    type: SortingLogicType.FUNCTION;
    sortingMethod: (firstValue: string, secondValue: string) => number;
  };
  [DemographicGeographicType.EDUCATION]: { type: SortingLogicType.HARDCODED; values: string[] };
} = {
  [DemographicGeographicType.AGE]: { type: SortingLogicType.HARDCODED, values: ['18-34', '35-54', '55+'] },
  [DemographicGeographicType.INCOME]: {
    type: SortingLogicType.FUNCTION,
    sortingMethod: (firstValue, secondValue) => {
      // regex extract first number from '$50,000-$74,999'
      const firstValueNumber = firstValue.match(/\d+/)?.[0];
      const secondValueNumber = secondValue.match(/\d+/)?.[0];
      if (firstValueNumber && secondValueNumber) {
        return parseInt(firstValueNumber) - parseInt(secondValueNumber);
      }
      return 1;
    },
  },
  [DemographicGeographicType.EDUCATION]: {
    type: SortingLogicType.HARDCODED,
    values: ['High school degree or less', 'Some college or college degree', 'Higher education degree'],
  },
};

const defaultCrossScore: CrossScore = {
  score: {
    positive: 0,
    negative: 0,
    neutral: 0,
  },
  totalSubmissions: 0,
};

/**
 * Sort the demographic and geographic choices by the order defined in the DEMOGRAPHICS_SORTING_ORDER constant.
 * If it is not defined there by type or by value, sort alphabetically.
 *
 * Note: This should not be hardcoded, but should instead be returned from the endpoint sorted.
 * The sorting should be done by the child quesiton choices' sort index.
 * This will be handled in this task: https://zencity.atlassian.net/browse/SRV-1071
 */
export const sortDemographicValues = (
  firstChoice: DemographicAndGeographicBreakdown,
  secondChoice: DemographicAndGeographicBreakdown,
  demographicGeographicType: DemographicGeographicType,
) => {
  const demographicSortingOrder =
    DEMOGRAPHICS_SORTING_ORDER?.[demographicGeographicType as keyof typeof DEMOGRAPHICS_SORTING_ORDER];
  if (!demographicSortingOrder) {
    const firstOption = isNumeric(firstChoice.text) ? parseInt(firstChoice.text) : firstChoice.text;
    const secondOption = isNumeric(secondChoice.text) ? parseInt(secondChoice.text) : secondChoice.text;
    return typeof firstOption === 'number' && typeof secondOption === 'number'
      ? firstOption - secondOption
      : firstChoice.text.localeCompare(secondChoice.text);
  }

  if (demographicSortingOrder.type === SortingLogicType.HARDCODED) {
    const firstChoiceIndex = demographicSortingOrder.values.indexOf(firstChoice.text);
    const secondChoiceIndex = demographicSortingOrder.values.indexOf(secondChoice.text);
    if (firstChoiceIndex !== -1 && secondChoiceIndex !== -1) {
      return firstChoiceIndex - secondChoiceIndex;
    }
    if (firstChoiceIndex === -1 && secondChoiceIndex === -1) {
      return firstChoice.text.localeCompare(secondChoice.text);
    }
    return 1;
  }
  if (demographicSortingOrder.type === SortingLogicType.FUNCTION) {
    return demographicSortingOrder.sortingMethod(firstChoice.text, secondChoice.text);
  }
  return 1;
};

const fillInEmptyGapsInDemographicGeographicData = (
  crossDemographicGeographicData: DemographicGeographicData[],
  currentDemographicGeographicTypeBreakdown: DemographicAndGeographicBreakdown[],
  demographicGeographicType: DemographicGeographicType,
) => {
  const demographicOptionsMapping = crossDemographicGeographicData.reduce<Record<DemographicGeographicType, string[]>>(
    (accumulator, demographivGeographicData) => {
      const { breakdown_by_cross: breakDownData } = demographivGeographicData;
      const flattenedBreakDownData = breakDownData.flat();
      flattenedBreakDownData.forEach((breakDown) => {
        if (!accumulator[breakDown.demographic_type].includes(breakDown.text)) {
          accumulator[breakDown.demographic_type] = [...accumulator[breakDown.demographic_type], breakDown.text];
        }
      });
      return accumulator;
    },
    {
      [DemographicGeographicType.AGE]: [],
      [DemographicGeographicType.EDUCATION]: [],
      [DemographicGeographicType.INCOME]: [],
      [DemographicGeographicType.AREA]: [],
    },
  );
  // fill in empty gaps when there is no choice selected for a demographic type, but it is a valid option
  return demographicOptionsMapping[demographicGeographicType].reduce<DemographicAndGeographicBreakdown[]>(
    (accumulator, demographicOption) => {
      if (!accumulator.find((demoGeoBreakDownData) => demoGeoBreakDownData.text === demographicOption)) {
        accumulator.push({
          demographic_type: demographicGeographicType,
          text: demographicOption,
          score: 0,
          total_submissions: 0,
        });
      }
      return accumulator;
    },
    currentDemographicGeographicTypeBreakdown,
  );
};

export const calculateScore = (
  crossScore: CrossScore,
  numericValue: Nullable<number>,
  score: number,
  totalSubmissions: number,
  positiveNumericValueThreshold: number,
  negativeNumericValueThreshold: number,
  sort_index?: number,
  // eslint-disable-next-line max-params
) => {
  if (numericValue && numericValue >= positiveNumericValueThreshold) {
    crossScore.score.positive += score;
  } else if (numericValue && numericValue <= negativeNumericValueThreshold) {
    crossScore.score.negative += score;
  } else if (numericValue) {
    crossScore.score.neutral += score;
  }
  crossScore.totalSubmissions += totalSubmissions;
  crossScore.sortIndex = sort_index;
};

export const calculateDemographicGeographicCross = (
  crossDemographicGeographicData: DemographicGeographicData[],
  demographicGeographicTypes?: DemographicGeographicType[],
): DemographicGeographicCross[] => {
  const scores: DemographicGeographicCross[] = [];

  if (demographicGeographicTypes?.length === 0) {
    return scores;
  }

  const sortedCrossDemographicGeographicData = crossDemographicGeographicData.sort(
    (choice1, choice2) => (choice1.numeric_value ?? -1) - (choice2.numeric_value ?? -1),
  );
  const numericValues = crossDemographicGeographicData.map((choice) => choice.numeric_value);
  const { negativeNumericValueThreshold, positiveNumericValueThreshold } =
    getOrderedNumericValueThresholds(numericValues);

  sortedCrossDemographicGeographicData.forEach((parentGroupType) => {
    const { numeric_value } = parentGroupType;

    demographicGeographicTypes?.forEach((demographicGeographicType) => {
      const demographicTypeIndex = scores.findIndex((score) => score[demographicGeographicType]);
      if (demographicTypeIndex === -1) {
        scores.push({
          [demographicGeographicType]: [],
        });
      }
      const demographicTypeScoreIndex =
        (demographicTypeIndex || demographicTypeIndex === 0) && demographicTypeIndex !== -1
          ? demographicTypeIndex
          : scores.length - 1;
      const scoresPerDemographicType = scores[demographicTypeScoreIndex][
        demographicGeographicType
      ] as DemographicGeographicScore[];

      const { breakdown_by_cross: breakDownData } = parentGroupType;

      const currentDemographicGeographicTypeBreakdown = fillInEmptyGapsInDemographicGeographicData(
        crossDemographicGeographicData,
        breakDownData.filter((breakdown) => breakdown.demographic_type === demographicGeographicType),
        demographicGeographicType,
      );

      const sortedBreakDown = currentDemographicGeographicTypeBreakdown.sort(
        (firstBreakdownChoice, secondBreakdownChoice) => {
          const sortedValue = sortDemographicValues(
            firstBreakdownChoice,
            secondBreakdownChoice,
            demographicGeographicType,
          );
          return sortedValue;
        },
      );
      sortedBreakDown.forEach((demographicBreakdown) => {
        const { text, score: scoreNum, total_submissions } = demographicBreakdown;
        const index = scoresPerDemographicType.findIndex((score) => score[text]);
        if (index === -1) {
          scoresPerDemographicType.push({
            [text]: {
              score: { ...defaultCrossScore.score },
              totalSubmissions: defaultCrossScore.totalSubmissions,
            },
          });
        }
        const demographicValueScoreIndex =
          (index || index === 0) && index !== -1 ? index : scoresPerDemographicType.length - 1;
        const scorePerDemographicValue = scoresPerDemographicType[demographicValueScoreIndex][text];
        calculateScore(
          scorePerDemographicValue,
          numeric_value,
          scoreNum,
          total_submissions,
          positiveNumericValueThreshold,
          negativeNumericValueThreshold,
        );
      });
    });
  });
  return scores;
};

export const calculateCrossQuestionChoices = (
  crossChoicesData: CrossChoiceData[],
  crossQuestionSelectedOptions?: SelectOption[],
): QuestionChoicesCross[] => {
  const scores: QuestionChoicesCross[] = [];

  if (!crossQuestionSelectedOptions?.length) {
    return scores;
  }

  const sortedCrossChoicesData = crossChoicesData.sort(
    (choice1, choice2) => Number(choice1.numeric_value) - Number(choice2.numeric_value),
  );
  const numericValues = crossChoicesData.map((choice) => choice.numeric_value);
  const { negativeNumericValueThreshold, positiveNumericValueThreshold } =
    getOrderedNumericValueThresholds(numericValues);

  crossQuestionSelectedOptions?.forEach((crossQuestionOption) => {
    sortedCrossChoicesData
      .filter((crossQuestion) => crossQuestion.cross_generic_question_id === crossQuestionOption.value)
      .forEach((crossQuestion) => {
        const { numeric_value } = crossQuestion;

        const crossQuestionIndex = scores.findIndex((score) => score[crossQuestionOption.label]);
        if (crossQuestionIndex === -1) {
          scores.push({
            [crossQuestionOption.label]: [],
          });
        }
        const crossQuestionScoreIndex =
          (crossQuestionIndex || crossQuestionIndex === 0) && crossQuestionIndex !== -1
            ? crossQuestionIndex
            : scores.length - 1;
        const scoresPerCrossQuestion = scores[crossQuestionScoreIndex][
          crossQuestionOption.label
        ] as CrossChoicesScore[];

        crossQuestion.breakdown_by_cross.forEach((choicesBreakdown) => {
          const { text, score: scoreNum, total_submissions, sort_index } = choicesBreakdown;
          const index = scoresPerCrossQuestion.findIndex((score) => score[text]);
          if (index === -1) {
            scoresPerCrossQuestion.push({
              [text]: {
                score: { ...defaultCrossScore.score },
                totalSubmissions: defaultCrossScore.totalSubmissions,
              },
            });
          }

          const questionChoiceScoreIndex =
            (index || index === 0) && index !== -1 ? index : scoresPerCrossQuestion.length - 1;
          const scorePerQuestionChoice = scoresPerCrossQuestion[questionChoiceScoreIndex][text];

          calculateScore(
            scorePerQuestionChoice,
            numeric_value,
            scoreNum,
            total_submissions,
            positiveNumericValueThreshold,
            negativeNumericValueThreshold,
            sort_index,
          );
        });
      });
  });
  return scores;
};

interface CrossTabsDataParams {
  client: LeanClient;
  cycles: SurveyCycle[];
  selectedDateRange: SelectedDateRange;
  selectedBaseGenericQuestionId?: number;
  crossQuestionSelectedOptions?: SelectOption[];
  demographicGeographic?: string[];
  surveyGroupId?: number;
}

const EMPTY_CROSS_TABS_DATA: CrossTabsWidgetData = {
  [CrossTabsDataTypes.CROSS_QUESTIONS]: [],
  [CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC]: [],
  [CrossTabsDataTypes.SCORE_TEXT_WITH_SENTIMENT_BAR]: [],
};

const findChoiceData = (
  crossQuestions: CrossTabsQuestionsData,
  relevantQuestionText: string,
  choiceTextToCompare: string,
  sortIndexToCompare?: Nullable<number>,
): CrossScore | undefined => {
  const questionIndex = crossQuestions.findIndex((questionData) => questionData[relevantQuestionText]);
  if (questionIndex === -1) return undefined;

  const questionData = crossQuestions[questionIndex];
  for (const [, crossChoices] of Object.entries(questionData)) {
    for (const choice of crossChoices) {
      const keys = Object.keys(choice);
      const choiceText = keys[0];
      const choiceData = choice[keys[0]];
      const choiceDataToReturn = {
        score: {
          positive: choiceData.score.positive,
          negative: choiceData.score.negative,
          neutral: choiceData.score.neutral,
        },
        totalSubmissions: choiceData.totalSubmissions,
      };
      if (sortIndexToCompare) {
        if (choiceData.sortIndex === sortIndexToCompare) {
          return choiceDataToReturn;
        }
      } else if (choiceText === choiceTextToCompare) {
        return choiceDataToReturn;
      }
    }
  }

  return undefined;
};

export const combineCyclesCrossQuestionsData = (
  crossQuestions: CrossTabsQuestionsData,
  crossQuestionsPrev: CrossTabsQuestionsData,
): CrossTabsQuestionsData => {
  Object.entries(crossQuestions).forEach(([, questionData]) => {
    Object.entries(questionData).forEach(([questionText, crossChoices]) => {
      crossChoices.forEach((choice) => {
        const choiceText = Object.keys(choice)[0];
        const choiceData = choice[choiceText];
        const optionDataPreviousCycle = findChoiceData(
          crossQuestionsPrev,
          questionText,
          choiceText,
          choiceData.sortIndex,
        );
        choiceData.previousCycle = optionDataPreviousCycle;
        choice[choiceText] = choiceData;
      });
    });
  });
  return crossQuestions;
};

export const getCrossTabsData = async (crossTabsDataParams: CrossTabsDataParams): Promise<CrossTabsWidgetData> => {
  const {
    client,
    cycles,
    selectedDateRange,
    selectedBaseGenericQuestionId,
    crossQuestionSelectedOptions,
    demographicGeographic,
    surveyGroupId,
  } = crossTabsDataParams;
  const filteredCycles = getCyclesStartingFromSelectedCycle(selectedDateRange, cycles);

  if (!selectedBaseGenericQuestionId || !filteredCycles.length) {
    return EMPTY_CROSS_TABS_DATA;
  }

  const lastCycleIndex = 0;
  const previousCycleIndex = 1;
  const mostRecentCycle = filteredCycles[lastCycleIndex];
  const previousCycle = filteredCycles[previousCycleIndex];
  try {
    const mostRecentCycleCrossTabsData = await fetchCrossTabQuestions({
      surveyGroupId,
      start_date: mostRecentCycle.startDate,
      end_date: mostRecentCycle.endDate,
      include_ignored_questions: true,
      question_id: selectedBaseGenericQuestionId,
      cross_question_ids: crossQuestionSelectedOptions?.map(
        (crossQuestionOption) => crossQuestionOption.value as number,
      ),
    });

    const scoreTextWithSentimentBarWidgetData = await getQuestionScoreData(
      client,
      filteredCycles,
      selectedDateRange,
      selectedBaseGenericQuestionId,
    );

    const demographicGeographicTypes = demographicGeographic?.map(
      (demographicType) => DemographicGeographicType[demographicType as keyof typeof DemographicGeographicType],
    );

    const mostRecentCycleCrossQuestionChoices = calculateCrossQuestionChoices(
      mostRecentCycleCrossTabsData.cross_choices_data,
      crossQuestionSelectedOptions,
    );

    const mostRecentCycleCrossDemographicGeographic = calculateDemographicGeographicCross(
      mostRecentCycleCrossTabsData.demographic_and_geographic_data,
      demographicGeographicTypes,
    );
    if (!previousCycle) {
      return {
        [CrossTabsDataTypes.CROSS_QUESTIONS]: mostRecentCycleCrossQuestionChoices,
        [CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC]: mostRecentCycleCrossDemographicGeographic,
        [CrossTabsDataTypes.SCORE_TEXT_WITH_SENTIMENT_BAR]: scoreTextWithSentimentBarWidgetData,
      };
    }
    const crossTabPreviousCycle = await fetchCrossTabQuestions({
      surveyGroupId,
      start_date: previousCycle.startDate,
      end_date: previousCycle.endDate,
      include_ignored_questions: true,
      question_id: selectedBaseGenericQuestionId,
      cross_question_ids: crossQuestionSelectedOptions?.map(
        (crossQuestionOption) => crossQuestionOption.value as number,
      ),
    });
    const previousCycleCrossQuestionChoices = calculateCrossQuestionChoices(
      crossTabPreviousCycle.cross_choices_data,
      crossQuestionSelectedOptions,
    );

    const previousCycleCrossDemographicGeographic = calculateDemographicGeographicCross(
      crossTabPreviousCycle.demographic_and_geographic_data,
      demographicGeographicTypes,
    );

    const crossQuestionData = combineCyclesCrossQuestionsData(
      mostRecentCycleCrossQuestionChoices,
      previousCycleCrossQuestionChoices,
    );
    const crossDemographicGeographicData = combineCyclesCrossQuestionsData(
      mostRecentCycleCrossDemographicGeographic,
      previousCycleCrossDemographicGeographic,
    );

    return {
      [CrossTabsDataTypes.CROSS_QUESTIONS]: crossQuestionData,
      [CrossTabsDataTypes.CROSS_DEMOGRAPHIC_GEOGRAPHIC]: crossDemographicGeographicData,
      [CrossTabsDataTypes.SCORE_TEXT_WITH_SENTIMENT_BAR]: scoreTextWithSentimentBarWidgetData,
    };
  } catch (error) {
    logger.error(error);
    return EMPTY_CROSS_TABS_DATA;
  }
};

export const mapCrossDataToSentimentBarGroups = (
  crossQuestions: CrossTabsQuestionsData,
): SentimentBarGroupsWidgetData[] => {
  const returnValue: SentimentBarGroupsWidgetData[] = [];
  Object.entries(crossQuestions).forEach(([, value]) => {
    const questionsData = Object.entries(value).map(([crossQuestion, crossChoices]) => ({
      groupTitle: crossQuestion,
      groupItems: crossChoices.map((choice) => {
        const keys = Object.keys(choice);
        const scoreData = choice[keys[0]];
        return {
          text: keys[0],
          score: {
            positive: scoreData.score.positive,
            neutral: scoreData.score.neutral,
            negative: scoreData.score.negative,
          },
          previousCycle: scoreData.previousCycle,
          totalSubmissions: scoreData.totalSubmissions,
        };
      }),
    }));
    returnValue.push(...questionsData);
  });

  return returnValue;
};
