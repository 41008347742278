/* eslint-disable max-lines-per-function,max-statements */
import { Flex } from 'components/Flex/Flex';
import { SurveyLanguageTranslateButton } from 'components/SurveyLanguageTranslateButton/SurveyLanguageTranslateButton';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useMemo } from 'react';
import {
  setCurrentDisplay,
  setCurrentLanguage,
  setSelectedSurveyLanguage,
  SurveyManageDisplay,
} from 'slices/surveyManage';
import { RootState } from 'store';
import { SurveyLanguage, SurveyStatus } from '@zencity/survey-types';
import { SurveyLanguagePublishButton } from 'components/SurveyLanguagePublishButton/SurveyLanguagePublishButton';
import { getSurveyLanguageStatusText } from 'components/SurveyLanguageItem/SurveyLanguageItem.helpers';
import { PublishStatusIcon } from './PublishStatusIcon';
import styles from './SurveyLanguageItem.module.scss';

interface Props {
  surveyLanguage: SurveyLanguage;
}

/**
 * This component contains options for publishing, translating, and sharing a survey by language.
 */
export const SurveyLanguageItem = (props: Props): ReactElement => {
  const { surveyLanguage } = props;

  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state: RootState) => state.questions);
  const { languages, defaultLanguage } = useAppSelector((state: RootState) => state.globalConfigs);
  const { selectedSurveyLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const { translationSections, currentSurvey } = useAppSelector((state: RootState) => state.surveyManage);

  const { status, closed_date: closedDate } = currentSurvey!;
  const { published_date, id: surveyLanguageId, language } = surveyLanguage;
  const publishedDate = published_date ?? undefined;

  const missingTranslationsCount = useMemo(() => {
    const allTranslationItems = translationSections
      .map((translationSection) => translationSection.translationItems)
      .flat();
    const filteredTranslationItemsByLanguage = allTranslationItems.filter(
      (translationItem) => !translationItem.translatedTexts[surveyLanguage.language],
    );
    return filteredTranslationItemsByLanguage.length;
  }, [translationSections, surveyLanguage]);

  const isPublished = !!publishedDate;

  const isClosed = status === SurveyStatus.CLOSED && !!closedDate;
  const isPublishable = missingTranslationsCount === 0 && questions.length > 0 && !publishedDate;
  const surveyItemRadioId = `survey-language-${surveyLanguageId}`;

  const statusText = getSurveyLanguageStatusText({
    isPublishable,
    missingTranslationsCount,
    closedDate,
    publishedDate,
  });

  const handleOnItemClick = (
    surveyDisplay: SurveyManageDisplay,
    event?: React.MouseEvent<HTMLDivElement | HTMLInputElement | HTMLButtonElement, MouseEvent>,
  ) => {
    if (!event) {
      return;
    }
    event.stopPropagation();
    dispatch(setSelectedSurveyLanguage(surveyLanguage));
    dispatch(setCurrentLanguage(language));
    dispatch(setCurrentDisplay(surveyDisplay));
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.surveyItem} onClick={(event) => handleOnItemClick(SurveyManageDisplay.PREVIEW, event)}>
      <section className={styles.content}>
        <div className={styles.radioOption}>
          <label htmlFor={surveyItemRadioId}>
            <input
              type="radio"
              checked={selectedSurveyLanguage?.id === surveyLanguageId}
              onClick={(event) => handleOnItemClick(SurveyManageDisplay.PREVIEW, event)}
              readOnly
            />
            {languages[language]}
            <PublishStatusIcon isPublishable={isPublishable} isPublished={isPublished} isClosed={isClosed} />
          </label>
        </div>
        <p className={styles.surveyStatusText}>{statusText}</p>
        <Flex flexDirection="column">
          {isPublishable && <SurveyLanguagePublishButton surveyLanguage={surveyLanguage} />}
          {language !== defaultLanguage && (
            <SurveyLanguageTranslateButton
              onClickHandler={(event) => handleOnItemClick(SurveyManageDisplay.TRANSLATION, event)}
            />
          )}
        </Flex>
      </section>
    </div>
  );
};
