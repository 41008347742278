import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { QuestionTypes } from '@zencity/survey-types';
import { SUPPORTED_QUESTION_TYPES } from 'components/LogicJumpDialog/LogicJumpDialog.helpers';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useState } from 'react';
import { RootState } from 'store';
import { Question, ResolvedIndexes } from 'types/questions';

interface Props {
  onChange: (updatedFormValues: LogicJumpFormItemChangeType) => void;
  logicJumpFormItem: LogicJumpFormItem;
}

export const generateSelectOptions = (
  questions: Question[],
  questionItemIndex: number,
  resolvedIndexes: ResolvedIndexes,
): SelectOption[] => {
  const supportedQuestions = questions.filter((question) =>
    SUPPORTED_QUESTION_TYPES.includes(question.question_type as QuestionTypes),
  );
  // The possible questions to select should only go up to the
  // Crossroad Questions index.
  const crossroadQuestionIndex = supportedQuestions.findIndex((question) => question.index === questionItemIndex);
  return questions
    .filter((question) => SUPPORTED_QUESTION_TYPES.includes(question.question_type as QuestionTypes))
    .map<SelectOption>((question) => ({
      value: `question-${question.id}`,
      label: `Question #${resolvedIndexes[question.id]}: ${question.text}`,
    }))
    .slice(0, crossroadQuestionIndex + 1);
};

const intializeSelectedOption = (
  selectOptions: SelectOption[],
  selectedOriginQuestionId?: number,
): SelectOption | undefined => {
  if (!selectedOriginQuestionId || !selectOptions.length) {
    return undefined;
  }
  return selectOptions.find((option) => option.value === `question-${selectedOriginQuestionId}`);
};

export const OriginQuestionSelect = (props: Props): ReactElement => {
  const { onChange, logicJumpFormItem } = props;
  const { questions } = useAppSelector((state: RootState) => state.questions);
  const { resolvedIndexes } = useAppSelector((state: RootState) => state.surveyManage);

  const { selectedOriginQuestionId, crossroadQuestionItem, inEditMode } = logicJumpFormItem;

  const selectOptions = generateSelectOptions(questions, crossroadQuestionItem.item.index, resolvedIndexes);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    intializeSelectedOption(selectOptions, selectedOriginQuestionId),
  );

  const handleOnSelect = (option: SelectOption) => {
    const questionId = parseInt(option.value.toString().split('-')[1]);
    const selectedQuestion = questions.find((question) => question.id === questionId);
    if (!selectedQuestion) {
      return;
    }

    onChange({
      selectedOriginQuestionId: selectedQuestion.id,
    });
    setSelectedOption(option);
  };

  return (
    <ZCDSelect
      options={selectOptions}
      value={selectedOption}
      isDisabled={!inEditMode}
      onChange={(newValue) => handleOnSelect(newValue as SelectOption)}
    />
  );
};
