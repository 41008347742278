import {
  reduceConfigsToResolvedValues,
  mapQuestionConfigToConfigType,
} from 'components/QuestionInformationDialog/components/ConfigsList/ConfigsList.helpers';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericQuestion } from 'types/genericQuestion';
import { QuestionConfig } from 'types/questionConfig';

interface Props {
  configs: QuestionConfig[];
  genericQuestion: GenericQuestion;
}

export const ConfigsList = ({ configs, genericQuestion }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const resolvedQuestionConfigValues = reduceConfigsToResolvedValues(mapQuestionConfigToConfigType(configs));
  const genericQuestionConfigs = reduceConfigsToResolvedValues(mapQuestionConfigToConfigType(genericQuestion.configs));

  return (
    <section>
      <h3>{translate('questionInformationDialog.configsList.questionConfigs')}</h3>
      <ul>
        {resolvedQuestionConfigValues.length === 0 ? (
          <li>
            <b>{translate('questionInformationDialog.configsList.noConfigs')}</b>
          </li>
        ) : (
          resolvedQuestionConfigValues.map((config) => <li key={config.id}>{config.configValue}</li>)
        )}
      </ul>
      <h3>{translate('questionInformationDialog.configsList.genericQuestionConfigs')}</h3>
      <ul>
        {genericQuestionConfigs.length === 0 ? (
          <li>
            <b>{translate('questionInformationDialog.configsList.noConfigs')}</b>
          </li>
        ) : (
          genericQuestionConfigs.map((config) => <li key={config.id}>{config.configValue}</li>)
        )}
      </ul>
    </section>
  );
};
