import React, { useEffect, useState } from 'react';
import {
  BarChartWidgetType,
  OverallSatisfactionScoreQuestion,
  sentimentColor,
} from 'screens/WidgetGenerator/utils/misc';
import { capitalizeString, chunkArray } from 'utils/misc';
import { v4 } from 'uuid';
import { BarChart } from '../BarChart';
import { getBarChartFilteredData } from '../barChartUtils';
import styles from './SatisfactionBarChart.module.scss';

interface SatisfactionBarChartProps {
  widgetData: OverallSatisfactionScoreQuestion[];
  isBenchmarked: boolean;
  isShortBarChart: boolean;
  splitPositionIndex?: number;
  numBarsPerRow?: number;
  // Will only render the specified row to dipslay based on the `numBarsPerRow`. This number is 1-indexed.
  rowToDisplay?: number;
  wideBars?: boolean;
}
const exclWords = ['for', 'to', 'and', 'of', 'that', 'a', 'an'];
const MAX_BARS_PER_ROW = 8;

export function SatisfactionBarChart(props: SatisfactionBarChartProps): React.ReactElement {
  const {
    widgetData,
    isBenchmarked,
    isShortBarChart,
    numBarsPerRow = 0,
    splitPositionIndex,
    rowToDisplay,
    wideBars,
  } = props;
  const filteredData = widgetData.length ? getBarChartFilteredData(widgetData) : [];
  const [barsPerRow, setBarsPerRow] = useState(numBarsPerRow);

  useEffect(() => {
    const shouldSetDefaultNumBarsPerRow = !splitPositionIndex && !numBarsPerRow && filteredData.length;
    if (shouldSetDefaultNumBarsPerRow) {
      const numChunks = Math.ceil(filteredData.length / MAX_BARS_PER_ROW);
      const defaultNumBarsPerRow = Math.ceil(filteredData.length / numChunks);
      setBarsPerRow(defaultNumBarsPerRow);
    } else if (splitPositionIndex) {
      setBarsPerRow(splitPositionIndex);
    }
  }, [numBarsPerRow, filteredData.length, splitPositionIndex]);

  const chunkedData = chunkArray(filteredData, barsPerRow);

  return (
    <>
      {chunkedData.map((chunk, index) => {
        // Since the `rowToDisplay` prop is 1-indexed, we need to add 1 to the index
        // to compare it.
        if (rowToDisplay && index + 1 !== rowToDisplay) {
          return null;
        }
        return (
          <div className={styles.satisfactionBarChartCont} key={v4()} data-testid="satisfaction-bar-chart">
            {chunk.map((item) => {
              const barText = capitalizeString({ string: item.text, wordsToExclude: exclWords });
              return (
                <BarChart
                  score={item?.score}
                  isLabeled
                  isShortBarChart={isShortBarChart}
                  fillColor={sentimentColor.positive}
                  label={barText}
                  showSpan={false}
                  key={v4()}
                  isBenchmarked={!!(isBenchmarked && item.previousCycle)}
                  benchmarkScore={item?.previousCycle?.score}
                  widgetType={BarChartWidgetType.MULTIPLE}
                  wideBars={wideBars}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
}
