import { ZCDIcon } from '@zencity/common-ui';
import classnames from 'classnames';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { TranslationItem } from 'components/SurveyTranslationsList/SurveyTranslationsList.helpers';
import { useAppSelector } from 'customHooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { MarkdownHOC } from 'components/MarkdownHOC/MarkdownHOC';
import styles from './SingleTranslationForm.module.scss';

interface Props {
  allowEditMode: boolean;
  translationItem: TranslationItem;
  isInEditMode?: boolean;
  handleOnEdit?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Contains the inputs and labels to edit a translation.
 */
export const SingleTranslationForm = (props: Props): ReactElement => {
  const { translationItem, allowEditMode, handleOnEdit, isInEditMode = false } = props;
  const { textToTranslate, originalTextToTranslate, translatedTexts, originalTranslatedTexts } = translationItem;
  const { selectedLanguage } = useAppSelector((state: RootState) => state.surveyManage);
  const { languages } = useAppSelector((state: RootState) => state.globalConfigs);
  const { t: translate } = useTranslation();

  const translatedText = translatedTexts[selectedLanguage];
  const originalTranslatedText = originalTranslatedTexts ? originalTranslatedTexts[selectedLanguage] : undefined;
  const showTranslatedInput = allowEditMode || translatedText;

  return (
    <div className={classnames(styles.translationForm, { [styles.equalWidthColumns]: isInEditMode })}>
      {/* The origin language will always be "English (en)". */}
      {allowEditMode && <p className={styles.originLanguageLabel}>{languages.en}</p>}
      <QuestionTextArea
        disabled
        value={textToTranslate}
        className={classnames(styles.contentInput, styles.originLanguageInput)}
      />
      {originalTextToTranslate && (
        <div className={classnames(styles.previousText, styles.originLanguagePreviousText)}>
          <MarkdownHOC text={`${translate('common.original')}: ${originalTextToTranslate}`} />
        </div>
      )}
      <div className={styles.arrowsIcon}>
        <ZCDIcon name="sort" />
      </div>
      {showTranslatedInput && (
        <>
          {allowEditMode && <p className={styles.translatedLanguageLabel}>{languages[selectedLanguage]}</p>}
          <QuestionTextArea
            disabled={!allowEditMode}
            value={translatedText}
            className={classnames(styles.contentInput, styles.translatedLanguageInput)}
            onChange={handleOnEdit}
            useDefaultValue
          />
          {originalTranslatedText && (
            <div className={classnames(styles.previousText, styles.translatedLanguagePreviousText)}>
              <MarkdownHOC text={`${translate('common.original')}: ${originalTranslatedText}`} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
