import { ActionItem } from 'components/ManageDropdownButton/ActionItem';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { RouterPath, SurveyChildPath } from 'routerPaths';

interface Props {
  surveyId: string;
}

export const EditSurveyActionItem = (props: Props): ReactElement => {
  const { surveyId } = props;
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleOnEditClick = () => {
    const surveysBuildPath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.BUILD}`, {
      surveyId,
    });
    const surveysManagePath = generatePath(`${RouterPath.SURVEY}/${SurveyChildPath.MANAGE}`, {
      surveyId,
    });

    navigate(surveysBuildPath, {
      state: {
        previousPath: surveysManagePath,
      },
    });
  };
  return <ActionItem iconName="edit" text={translate('common.edit')} onClick={handleOnEditClick} />;
};
