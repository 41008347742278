import { ZCDButton } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SurveyLanguageTranslateButton.module.scss';

interface Props {
  onClickHandler: (event?: React.MouseEvent<HTMLDivElement | HTMLInputElement | HTMLButtonElement, MouseEvent>) => void;
}

/**
 * This button should display when there are
 * missing translations for a Survey Language.
 */
export const SurveyLanguageTranslateButton = (props: Props): ReactElement => {
  const { onClickHandler } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.container}>
      <ZCDButton
        text={translate('common.translate')}
        type="button"
        size="md"
        variant="secondary"
        onClick={onClickHandler}
      />
    </div>
  );
};
