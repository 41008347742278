import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import {
  LogicJumpFormItem,
  LogicJumpFormItemChangeType,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import React, { ReactElement, useState } from 'react';
import { Choice, Question } from 'types/questions';

interface Props {
  logicJumpFormItem: LogicJumpFormItem;
  onChange: (updatedFormValues: LogicJumpFormItemChangeType) => void;
  selectedOriginQuestion: Question;
}

const generateSelectOptions = (choices: Choice[]): SelectOption[] =>
  choices.map<SelectOption>((choice) => ({
    value: choice.id,
    label: choice.label,
  }));

const intializeSelectedOption = (
  selectOptions: SelectOption[],
  conditionValue?: string | number,
): SelectOption | undefined => {
  if (!conditionValue || !selectOptions.length) {
    return undefined;
  }
  return selectOptions.find((option) => option.value === conditionValue);
};

export const QuestionChoiceSelect = (props: Props): ReactElement => {
  const { logicJumpFormItem, onChange, selectedOriginQuestion } = props;
  const { conditionValue, inEditMode } = logicJumpFormItem;
  // This component only renders when there is a selected Origin Question,
  // so we can assert it as true.
  const { choices = [] } = selectedOriginQuestion;

  const selectOptions = generateSelectOptions(choices);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    intializeSelectedOption(selectOptions, conditionValue),
  );

  const handleOnSelect = (newSelectedOption: SelectOption) => {
    setSelectedOption(newSelectedOption);
    onChange({
      conditionValue: newSelectedOption.value,
    });
  };

  return (
    <ZCDSelect
      options={selectOptions}
      value={selectedOption}
      isDisabled={!inEditMode}
      onChange={(newValue) => handleOnSelect(newValue as SelectOption)}
    />
  );
};
