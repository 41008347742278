import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { markdownToDraft } from 'markdown-draft-js';
import {
  convertEditorStateToMarkdown,
  convertMarkdownTextToTextAreaEvent,
} from 'components/QuestionTextArea/QuestionTextArea.helpers';
import styles from './QuestionTextArea.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  className?: string;
  useDefaultValue?: boolean;
}

// The Editor should only allow for Bold and Italic text. Underline and Code are not supported,
// but are defaulted by the Editor.
const CUSTOM_STYLE_MAP = {
  BOLD: {
    fontWeight: 'bold',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  UNDERLINE: {
    textDecoration: 'none',
  },
  CODE: {
    textDecoration: 'none',
  },
};

/**
 * A helper component for uniform styling of text areas in the question components.
 */
export const QuestionTextArea = (props: Props): ReactElement => {
  const { value, placeholder, onChange, disabled, className, useDefaultValue = false } = props;
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromRaw(
        markdownToDraft(value ?? '', {
          preserveNewlines: true,
        }),
      ),
    ),
  );

  useEffect(() => {
    if (useDefaultValue) {
      const newEditorState = EditorState.createWithContent(
        convertFromRaw(
          markdownToDraft(value ?? '', {
            preserveNewlines: true,
          }),
        ),
      );
      setEditorState(newEditorState);
    }
  }, [value, useDefaultValue]);

  const handleOnEditorChange = (changedEditorState: EditorState) => {
    const markdownString = convertEditorStateToMarkdown(changedEditorState);
    setEditorState(changedEditorState);

    const textAreaEvent = convertMarkdownTextToTextAreaEvent(markdownString);
    onChange?.(textAreaEvent);
  };

  const handleOnKeyCommand = (command: string, changedEditorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(changedEditorState, command);

    if (newState) {
      handleOnEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div className={classNames(styles.textArea, className, { [styles.disabled]: disabled })}>
      <Editor
        editorState={editorState}
        onChange={handleOnEditorChange}
        handleKeyCommand={handleOnKeyCommand}
        customStyleMap={CUSTOM_STYLE_MAP}
        placeholder={placeholder}
        readOnly={disabled}
      />
    </div>
  );
};
